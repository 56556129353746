ACC.shareonsocialnetworkaction = {

}

$(document).ready(function ()
{
	with (ACC.shareonsocialnetworkaction)
	{

	}
});


