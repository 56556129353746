ACC.productdetails = {

    _autoload: [
        "addToCartButtonToggle",
        "productPopoverEvent"
    ],

    addToCartButtonToggle: function () {
        $('.js-add-to-cart').click(function (e) {
            e.preventDefault();

            var selectedVariantFromPage = $('.js-selected-variant :selected').data('productcode');
            var addToCartMultiplierFromPage = $('.js-selected-variant :selected').data('addtocartmultiplier');
            var selectedQuantityFromPage = $('.product-quantity input').val();
            var data = {
                productCodePost: selectedVariantFromPage,
                qty: selectedQuantityFromPage * (addToCartMultiplierFromPage ? addToCartMultiplierFromPage : 1)
            }
            data.qty = (data.qty >= 1) ? data.qty : 1;

            var $that = $(this);
            $.ajax({
                url: $('#addToCartForm').attr('action'),
                type: 'POST',
                data: ACC.global.serialize(data),
                complete: function (jqXHR, textStatus) {
                    ACC.minicart.updateMiniCartDisplay();
                    if (jqXHR.status == 200) {
                        $that.addClass('btn-added')
                            .attr('disabled', 'disabled')
                            .removeClass('btn-primary');
                        var newText = $("#cartAddedBtnText").html();
                        var cartBtn = $that.html();
                        $that.html(newText);
                        setTimeout(function() {
                            $that.removeAttr('disabled')
                                .addClass('btn-primary')
                                .removeClass('btn-added')
                                .html(cartBtn);
                        }, 3000);
                    }
                },
            });

        });
    },

    enableAddToCartButton: function () {
        $('.js-enable-btn').each(function () {
            if (!($(this).hasClass('outOfStock') || $(this).hasClass('out-of-stock'))) {
                $(this).prop("disabled", false);
            }
        });
    },
    
    productPopoverEvent: function () {
        var popover = $('[data-toggle="popover"]');

        popover.popover();

        $('body').on('click touchstart', function (e) {
            popover.each(function () {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && popover.has(e.target).length === 0) {
                    $(this).popover('hide').data('bs.popover').inState.click = false
                }
            });
        });
    },
};


$(document).ready(function () {
    ACC.productdetails.enableAddToCartButton();
});