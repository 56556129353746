/*Babel ES6 transpiled!*/'use strict';

ACC.agegate = {

    _autoload: ["bindAgeCheck"],

    bindAgeCheck: function /*Babel ES6 transpiled!*/bindAgeCheck() {
        //shareSessionStorageAcrossTabs();
        window.onload = new function () {
            var isLoginPage = $('.page-login').length > 0;
            var isFFCustomer = ACC.config.isFFCustomer === 'true';
            var isAgeNotYetConfirmed;
            var loginHasNoError = $('.generic-login-error').length < 1;
            var isNotB2BCustomer = ACC.config.isB2bCustomer === 'false';
            var isMarketingPage = window.location.href.indexOf(window.location.hostname + ACC.marketingPageUrl);
            var isSSAUSite = ACC.config.isSsAuSite === 'true';

            //Removed existing AgeConfirmed session if not Staff sales in login page
            if (isSSAUSite) {
                localStorage.setItem('AgeConfirmed', 'true');
            } else if (isLoginPage) {
                localStorage.removeItem('AgeConfirmed');
            }

            // To give time for the session to populate
            setTimeout(function () {
                var isAgeNotYetConfirmed = localStorage.getItem('AgeConfirmed') === null;
                if ((isLoginPage && loginHasNoError && isAgeNotYetConfirmed || !isFFCustomer && isAgeNotYetConfirmed) && isNotB2BCustomer || isFFCustomer && isAgeNotYetConfirmed && isMarketingPage == -1) {
                    ACC.colorbox.open({
                        href: ACC.urls.ageGateUrl,
                        width: "709px",
                        height: false,
                        fixed: false,
                        top: false,
                        overlayClose: false,
                        className: 'age-gate age-confirmation-container',
                        onComplete: function /*Babel ES6 transpiled!*/onComplete() {
                            $.colorbox.resize();
                            $(document).on("click", ".js-age-cancel", function () {
                                window.location.href = ACC.urls.alcoholUrl;
                            });
                            $(document).on("click", ".js-age-proceed", function () {
                                localStorage.setItem('AgeConfirmed', 'true');
                                ACC.colorbox.close();
                            });
                        },
                        onError: function /*Babel ES6 transpiled!*/onError(err) {
                            console.error(err);
                        }
                    });
                }
            }, 1000);
        }();

        /* COMMENT FOR FUTURE USE/REFERENCE
        function shareSessionStorageAcrossTabs() {
            if (!sessionStorage.length) {
                // Ask other tabs for session storage
                localStorage.setItem('getSessionStorage', Date.now());
            }
             window.addEventListener('storage', function (event) {
                if (event.key == 'getSessionStorage') {
                    // Some tab asked for the sessionStorage -> send it
                    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
                    localStorage.removeItem('sessionStorage');
                } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
                    // sessionStorage is empty -> fill it
                    var data = JSON.parse(event.newValue);
                    for (key in data) {
                        sessionStorage.setItem(key, data[key]);
                    }
                }
            })
        } */
    }

};
