/*Babel ES6 transpiled!*/'use strict';

ACC.loader = {
  showLoadingScreen: function /*Babel ES6 transpiled!*/showLoadingScreen() {
    var loadingHtml = /*Babel ES6 transpiled!*/'<div class="loading-screen">\n                        <div class="spinner">\n                          <div></div>\n                          <div></div>\n                          <div></div>\n                          <div></div>\n                        </div>\n                      </div>';
    $('body').append(loadingHtml);
  },
  hideLoadingScreen: function /*Babel ES6 transpiled!*/hideLoadingScreen() {
    // add a tiny delay before removing the loading screen
    // so that it does not appear abrupt
    setTimeout(function () {
      $('.loading-screen').remove();
    }, 500);
  },
  stopButtonClicks: function /*Babel ES6 transpiled!*/stopButtonClicks(e) {
    e.preventDefault();
    e.stopPropagation();
  },
  showLoadingOnButton: function /*Babel ES6 transpiled!*/showLoadingOnButton(btnSelector) {
    var $button = $(btnSelector);
    $button.addClass('btn-processing');
    $button.click(ACC.loader.stopButtonClicks);
  },
  hideLoadingOnButton: function /*Babel ES6 transpiled!*/hideLoadingOnButton(btnSelector) {
    var $button = $(btnSelector);
    $button.removeClass('btn-processing');
    $button.unbind('click', ACC.loader.stopButtonClicks);
  },
  showLoadingOnDiv: function /*Babel ES6 transpiled!*/showLoadingOnDiv(divSelector) {
    var $div = $(divSelector);
    $div.addClass('container-loading');
  },
  hideLoadingOnDiv: function /*Babel ES6 transpiled!*/hideLoadingOnDiv(divSelector) {
    var $div = $(divSelector);
    $div.removeClass('container-loading');
  }
};
