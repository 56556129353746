ACC.listorderformaction = {

}

$(document).ready(function ()
{
	with (ACC.listorderformaction)
	{

	}
});


