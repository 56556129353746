/*Babel ES6 transpiled!*/"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var addressFormId = '#manage-address-form';
var autoCompleteResultsToShow = 5;

/*Babel ES6 transpiled!*/var ManageAddress = function () {
  function ManageAddress() {
    _classCallCheck(this, ManageAddress);
  }

  _createClass(ManageAddress, [{
    key: "bindAddEditAddressPopup",
    value: function bindAddEditAddressPopup() {
      var that = this;
      $(document).on("click", ".js-add-address", function (e) {
        e.preventDefault();
        var isEditMode = $(this).hasClass('js-edit-address');
        ACC.colorbox.open({
          href: $(this).data("link"),
          width: "709px",
          height: false,
          fixed: false,
          top: false,
          escKey: false,
          overlayClose: false,
          className: 'add-address-container',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            that.bindFormEvents();
            $.colorbox.resize();
            if (isEditMode) {
              that.unbindAddressAutoComplete();
              $('#btnAdd').addClass('hide');
              $('#btnEdit').removeClass('hide');
            } else {
              that.bindAddressAutoComplete();
              $('#btnAdd').removeClass('hide');
              $('#btnEdit').addClass('hide');
              that.addressLookup();
              that.toggleSelectColour();
              that.streetNumberInput();
              that.isAndroid();
              var documentHeight = $(document).height();
              $('input').on('blur keyup change', function () {
                $.colorbox.resize();
                that.modalPosition(documentHeight);
              });
              $('#btnAdd').on('blur focus', function () {
                that.modalPosition(documentHeight);
              });
              $('.ui-autocomplete').on('click', function () {
                that.modalPosition(documentHeight);
              });
            }

            $(document).on("click", ".js-address-cancel", function () {
              ACC.colorbox.close();
            });
          }
        });
      });
    }
  }, {
    key: "unbindAddressAutoComplete",
    value: function unbindAddressAutoComplete() {
      if ($('.js-address-search').data('ui-autocomplete') != undefined) {
        jQuery('.js-address-search').removeData('autocomplete');
        $('.add-address__unlisted').find('input:text').val('');
        $('.address-state').prop('selectedIndex', 0);
        $('#postcode').val('');
      }
    }
  }, {
    key: "bindAddressAutoComplete",
    value: function bindAddressAutoComplete() {
      var that = this;
      $('#isManualEntry').val('false');
      var documentHeight = $(document).height();

      $('.js-address-search').autocomplete({
        source: function /*Babel ES6 transpiled!*/source(request, response) {
          $.ajax({
            type: "POST",
            dataType: 'json',
            url: ACC.urls.fetchAddress,
            data: {
              address: $("#address").val()
            },
            success: function /*Babel ES6 transpiled!*/success(data) {
              var responseData = [];
              if (data.addresses && data.addresses.length) {
                responseData = data.addresses.map(function (address) {
                  return {
                    value: address.fullAddress,
                    label: address.fullAddress,
                    id: address.dpid
                  };
                }).slice(0, autoCompleteResultsToShow);
              }

              if (!responseData) {
                responseData.push({ value: ' ', label: ACC.addressBook.addressNotFound, id: 0 });
              } else {
                responseData.push({ value: ACC.addressBook.noMatchingAddress, label: ACC.addressBook.noMatchingAddress, id: 0 });
              }
              return response(responseData);
            },
            error: function /*Babel ES6 transpiled!*/error(jqXHR, textStatus, errorThrown) {
              // error handling
              console.error(textStatus);
            }
          });
        },
        dataType: "json",
        minLength: 5,
        select: function /*Babel ES6 transpiled!*/select(event, ui) {
          that.selectAddress(ui.item.id);
          that.unbindAddressAutoComplete();
        },
        close: function /*Babel ES6 transpiled!*/close(event, ui) {
          $.colorbox.resize();
        },
        open: function /*Babel ES6 transpiled!*/open(event, ui) {
          $.colorbox.resize();
          that.modalPosition(documentHeight);
        }
      });
      that.modalPosition(documentHeight);
    }
  }, {
    key: "fetchAddress",
    value: function fetchAddress() {
      var that = this;
      $.ajax({
        type: "POST",
        dataType: 'json',
        url: ACC.urls.fetchAddress,
        data: {
          address: $("#address").val()
        },
        success: that.addressSearchSuccess,
        error: function /*Babel ES6 transpiled!*/error(jqXHR, textStatus, errorThrown) {
          // error handling
          console.error(textStatus);
        }
      });
    }
  }, {
    key: "bindRemoveAddress",
    value: function bindRemoveAddress() {
      $('.js-remove-address').click(function (e) {
        e.preventDefault();
        var addressId = $(this).data("address-id");
        var removeUrl = $(this).data("remove-url");
        var modal = $(this).data("link");
        interrupt = function /*Babel ES6 transpiled!*/interrupt() {
          ACC.colorbox.open({
            href: modal,
            width: "709px",
            height: false,
            fixed: true,
            top: false,
            className: 'confirm-venue-container variantSelectMobile',
            onComplete: function /*Babel ES6 transpiled!*/onComplete() {
              $(addressFormId).addClass("hide");
              $("#unable-to-remove").removeClass("hide");
              $.colorbox.resize();
              $(document).on("click", ".js-address-back", function () {
                ACC.colorbox.close();
              });
            }
          });
        };
        ACC.colorbox.open({
          href: $(this).data("link"),
          width: "709px",
          height: false,
          fixed: true,
          top: false,
          className: 'confirm-venue-container variantSelectMobile',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            $(addressFormId).addClass("hide");
            $("#remove-address").removeClass("hide");
            $('.js-address-remove').click(function () {
              $.ajax({
                url: removeUrl,
                type: "POST",
                success: function /*Babel ES6 transpiled!*/success(response) {
                  if (response === "ERROR") {
                    ACC.colorbox.close();
                    setTimeout(function () {
                      interrupt();
                    }, 1000);
                  } else {
                    window.location.replace(response);
                  }
                }
              });
            });

            $(document).on("click", ".js-address-cancel", function () {
              ACC.colorbox.close();
            });
            $.colorbox.resize();
          }
        });
      });
    }
  }, {
    key: "bindAddressFormSubmit",
    value: function bindAddressFormSubmit() {

      var that = this;
      $(document).on('submit', addressFormId, function (event) {
        if ($('.address-state').val() === "" || $('#postcode').val() === "" || $('#streetNum').val() === "") {
          $("#address").parent().addClass("has-error");
          $("#address").addClass("has-error");
          $("<div class='input-msg input-error modal-error-message address-lookup_error'>" + ACC.addressBook.addressLookupError + "</div>").insertAfter($("#address").parent());
          $.colorbox.resize();
          event.preventDefault();
        } else {
          event.preventDefault(); //prevent default action
          var saveButtonId = '#btnAdd';
          // show loading indicator on save button
          ACC.loader.showLoadingOnButton(saveButtonId);
          var post_url = $(this).attr("action"); //get form action url
          var return_url = $(this).attr("returnurl"); //get form action url
          var request_method = $(this).attr("method"); //get form GET/POST method
          var form_data = $(this).serialize(); //Encode form elements for submission
          var sectionToReplace = addressFormId;
          $.ajax({
            url: post_url,
            type: request_method,
            data: form_data
          }).done(function (response) {
            if ($(response).find('.form-error-message').length > 0) {
              // hide loading indicator
              ACC.loader.hideLoadingOnButton(saveButtonId);
              $(sectionToReplace).replaceWith($(response));
              that.bindFormEvents();
              $("#address").addClass("js-address-search");
              that.bindAddressAutoComplete();
              that.addressLookup();
              $.colorbox.resize();
            } else {
              window.location.replace(return_url);
            }
          });
        }
      });
    }
  }, {
    key: "bindFormEvents",
    value: function bindFormEvents() {
      $(addressFormId).removeClass('hide');
      this.initializeForm();
      this.bindFormValidation();
    }
  }, {
    key: "bindFormValidation",
    value: function bindFormValidation() {
      var that = this;
      var documentHeight = $(document).height();

      $(addressFormId).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        onkeyup: function /*Babel ES6 transpiled!*/onkeyup(element) {
          if (element.id == "postcode") {
            $(element).valid();
            that.modalPosition(documentHeight);
          }
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          var $element = $(element);
          if ($element.hasClass('no-error-image')) $element.closest('.form-group').addClass('no-image');
          $element.closest('.form-group').addClass('has-error');
          $element.addClass('has-error').removeClass('is-valid');
          $.colorbox.resize();
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          var $element = $(element);
          if ($element.hasClass('no-error-image')) $element.closest('.form-group').removeClass('no-image');
          $element.closest('.form-group').removeClass('has-error');
          $element.removeClass('has-error').addClass('is-valid');
          $.colorbox.resize();
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error modal-error-message',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          error.insertAfter(element.parent());
          $.colorbox.resize();
        },
        rules: {
          firstName: { lionname: true },
          lastName: { lionname: true },
          townCity: {
            maxlength: 35
          },
          phone: {
            countryCodePhoneNumber: true,
            phonenumber: true,
            maxlength: 13
          }
        },
        messages: {
          phone: {
            phonenumber: ACC.messageLabels.phoneNumber,
            maxlength: ACC.messageLabels.length
          },
          postcode: { phonenumber: ACC.addressBook.postcodeNumber }
        }
      });
    }
  }, {
    key: "initializeForm",
    value: function initializeForm() {
      $(addressFormId).find('input').each(function () {
        $(this).trigger('focus');
        $(this).trigger('blur');
      });
    }
  }, {
    key: "selectAddress",
    value: function selectAddress(dpid) {
      $(".address-lookup_error").addClass("hidden");
      if (dpid === 0) {
        this.unbindAddressAutoComplete();
        $('.add-address__unlisted').removeClass('hidden');
        $('.manage-address-form .ui-autocomplete').removeClass("autocomplete__open");
        $('.address-state option:last-child').text(ACC.addressBook.otherTerritories);
        $('#isManualEntry').val("true");
        $.colorbox.resize();
        return;
      }
      $('.add-address__unlisted').addClass('hidden');
      var that = this;
      $.ajax({
        type: "POST",
        dataType: 'json',
        url: ACC.urls.addressDetails,
        data: { dpid: dpid },
        success: function /*Babel ES6 transpiled!*/success(result) {
          if (result.details && result.details.length > 0) {
            var addressDetail = result.details[0];

            if (ACC.config.isNzSite === "true" || !ACC.config.isB2bCustomer && !ACC.config.isFFCustomer) {
              $('#address').val(addressDetail.streetNumber + " " + addressDetail.addressLine1 + ", " + addressDetail.suburb + " " + addressDetail.cityTown + " " + addressDetail.postcode);
              $('#townCity').val(addressDetail.cityTown);
              $('#suburb').val(addressDetail.suburb);
            } else {
              $('#address').val((addressDetail.addressLine2 ? addressDetail.addressLine1 + ", " : "") + addressDetail.streetNumber + " " + (addressDetail.addressLine2 ? addressDetail.addressLine2 : addressDetail.addressLine1) + ", " + addressDetail.cityTown + " " + (addressDetail.region.isocode ? addressDetail.region.isocode + " " : "") + addressDetail.postcode);
              $('.address-state').val(ACC.config.isocode + "-" + addressDetail.region.isocode);

              $('#suburb').val(addressDetail.cityTown);
            }
            $('#isManualEntry').val("false");
            $('#postcode').val(addressDetail.postcode);
            $('#streetNum').val(addressDetail.streetNumber);
            if (ACC.config.isNzSite) {
              $('#addressLine1').val(addressDetail.addressLine1);
              $('#addressLine2').val(addressDetail.addressLine2);
            } else {
              if (addressDetail.addressLine2.length === 0) {
                $('#addressLine1').val(addressDetail.addressLine1);
                $('#addressLine2').val(addressDetail.addressLine2);
              } else {
                $('#addressLine1').val(addressDetail.addressLine2);
                $('#addressLine2').val(addressDetail.addressLine1);
              }
            }

            that.initializeForm();
          }
        }
      });
    }
  }, {
    key: "addressLookup",
    value: function addressLookup() {
      var that = this;
      $("#address").keyup(function () {
        $.colorbox.resize();
        that.unbindAddressAutoComplete();
        if (!$('.address-lookup_error').hasClass('hidden')) {
          $('.address-lookup_error').addClass('hidden');
          $('#address').parent().removeClass('has-error');
          $('#address').removeClass('has-error');
        }
        if ($('.add-address__unlisted').hasClass('hidden')) {
          $('.manage-address-form .ui-autocomplete').addClass("autocomplete__open");
        }
      });
    }
  }, {
    key: "toggleSelectColour",
    value: function toggleSelectColour() {
      $('.address-state').change(function () {
        if (this.value === '') {
          this.classList.add('untouched');
        } else {
          this.classList.remove('untouched');
        }
      });
    }

    //change street number label when there is an inline error for mobile 

  }, {
    key: "streetNumberInput",
    value: function streetNumberInput() {
      $('#streetNum').on('blur', function (e) {
        if ($(this).hasClass('has-error')) {
          $('.streetNum-mobile').html(ACC.addressBook.streetNumberMobileError);
        } else {
          $('.streetNum-mobile').html(ACC.addressBook.streetNumberMobile);
        }
      }).on('focus', function (e) {
        $('.streetNum-mobile').html(ACC.addressBook.streetNumberMobile);
      });
    }
  }, {
    key: "isAndroid",
    value: function isAndroid() {
      //this handles the positioning of the modal when android soft keyboard is displayed
      if (navigator.userAgent.match(/Android/i)) {
        $('#colorbox').addClass('device-android');
        $('input').on('focus', function (event) {
          $('#colorbox').addClass('device-android-input');
          var center = $('#cboxContent').height() / 2;
          var top = $(this).offset().top;
          if (top > center) {
            $('#cboxContent').scrollTop(top - center);
          }
        }).on('blur', function (event) {
          $('#colorbox').removeClass('device-android-input');
          //When user focus' out of input field, resize modal
          var target = $(event.relatedTarget);
          if (!target.is('input')) {
            $.colorbox.resize();
          }
        });
      }
    }
  }, {
    key: "modalPosition",
    value: function modalPosition(documentHeight) {
      var colorboxHeight = $('#colorbox').height();
      var colorboxTop = parseInt(document.getElementById('colorbox').style.top);
      var maxTop = documentHeight - colorboxHeight - 50;
      if (colorboxTop > maxTop) {
        $('#colorbox').css('top', maxTop);
      }
    }
  }]);

  return ManageAddress;
}();

$(function () {
  var manageAddress = new ManageAddress();
  if ($('.address-book').length > 0) {
    manageAddress.bindAddEditAddressPopup();
    manageAddress.bindRemoveAddress();
    manageAddress.bindFormEvents();
    manageAddress.bindAddressAutoComplete();
    manageAddress.bindAddressFormSubmit();
  }
});
