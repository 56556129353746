$(document).ready(function () {

    /* Allow only numeric during key press */
    $(document).on('blur', 'input[type="number"]', function (e) {
        var $this = $(this);
        $this.val($this.val().replace(/[^\d].+/, ""));
    });

    $(document).on('keypress keyup', 'input[type="number"]', function (e) {
        var key = e.which || e.keyCode;

        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers   
            key >= 48 && key <= 57 ||
            // Numeric keypad
            key >= 96 && key <= 105 ||
            // comma, period and minus, . on keypad
            key == 190 || key == 188 || key == 109 || key == 110 ||
            // Backspace and Tab and Enter
            key == 8 || key == 9 || key == 13 ||
            // Home and End
            key == 35 || key == 36 ||
            // left and right arrows
            key == 37 || key == 39 ||
            // Del and Ins
            key == 46 || key == 45)
            return true;

        return false;
    })

    $(document).on('keyup blur', 'input[type="number"]', function (e) {
        var $this = $(this);
        var min = $this.attr("min"),
            max = $this.attr("max"),
            value = parseInt($this.val() || 0);
        if (min !== undefined) {
            if (value < parseInt(min)) {
                $this.addClass('input-error');
                $this.val(null);
            } else {
                $this.removeClass('input-error');
            }
        }
        if (max !== undefined) {
            if (value > parseInt(max)) {
                $this.addClass('input-error');
                $this.val(null);
            } else {
                $this.removeClass('input-error');
            }
        }
    })

    // Adding a second layer of validation for input maxlength as that attribute
    // is not respected on android chrome and Samsung internet browsers
    $(document).on('keypress keyup blur', 'input[maxlength]', function(e) {
        // Store the maxlength and value of the field.
        var maxlength = $(this).attr('maxlength');
        var val = $(this).val();
    
        // Trim the field if it has content over the maxlength.
        if (val.length > maxlength) {
            $(this).val(val.slice(0, maxlength));
        }
    });

    jQuery.validator.addMethod("lettersonly", function(value, element) {
        return this.optional(element) || /^[a-z\s]+$/i.test(value);
    }, ACC.messageLabels.alphabetic);

    jQuery.validator.addMethod("lionname", function(value, element) {
        return this.optional(element) || /^[a-z\s\-_—'–]+$/i.test(value);
        // [a-z\s\-_—'–]    The string accepts letters and hyphen: -, en dash: –, em dash: —, apostrophe: '
    }, ACC.messageLabels.alphabetic);

    jQuery.validator.addMethod("alphanumeric", function(value, element) {
        return this.optional(element) || /^[a-z\d\-\s]+$/i.test(value);
    }, ACC.messageLabels.alphanumeric);

    jQuery.validator.addMethod("ponumber", function(value, element) {
        return this.optional(element) || /^[a-z\d]+$/i.test(value);
    }, ACC.messageLabels.alphanumeric);

    jQuery.validator.addMethod("address", function(value, element) {
        return this.optional(element) || /^[A-Za-z0-9\s\/\-\,]+$(\.0-9+)?/i.test(value);
    }, ACC.messageLabels.alphanumeric);

    jQuery.validator.addMethod("ausphonenumber", function(value, element) {
        return this.optional(element) || /^(?:0)?([23478])(\d{4})(\d{4})$/i.test(value);
    }, ACC.messageLabels.phonenumber);

    jQuery.validator.addMethod("countryCodePhoneNumber", function(value, element) {
        if (ACC.config.isNzSite === 'true'){
            return this.optional(element) || /^\+64.*$/i.test(value);
        } else {
            return this.optional(element) || /^\+61.*$/i.test(value);
        }
    }, ACC.messageLabels.countryCode);
    
    jQuery.validator.addMethod("phonenumber", function(value, element) {
        return this.optional(element) || /^[0-9]*$/i.test(value);
    }, ACC.messageLabels.phonenumber);

    jQuery.validator.addMethod("town", function(value, element) {
        return this.optional(element) || /^[a-z\s\-]+$/i.test(value);
    }, ACC.messageLabels.alphabetic);

    jQuery.validator.addMethod("email", function(value, element) {
          return this.optional(element) || /^[_A-Za-z0-9-\+]+([\.\'][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/i.test(value);
    }, ACC.messageLabels.emailError);

    jQuery.validator.addMethod('fileupload', function(value, element) {
        var hasFiles = $(element)
            .parents('.upload-container')
            .find('.file-list--item').length > 0;
        return hasFiles;
      }, null);

    jQuery.validator.addMethod("lionpassword", function(value, element) {
        //return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,128}$/.test(value);
        return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=[^#<>]+$).{10,128}$/.test(value);
        // (?=.*\d)	     The string must contain at least 1 numeric character
        // (?=.*[a-z])	 The string must contain at least 1 lowercase alphabetical character
        // (?=.*[A-Z])	 The string must contain at least 1 uppercase alphabetical character
        // (?=[^#<>]+$)  The string must not contain #,<,>
        // (?=.{10,128}) The string must be at least ten characters long
    }, ACC.messageLabels.passwordError);

    jQuery.validator.addMethod('confirmpassword', function(value, element) {
        // Compare against a .js-confirm-password field.
        var passwordField = $('.js-confirm-password[type="password"]');
        return this.optional(element) || value === passwordField.val();
      }, ACC.messageLabels.confirmPasswordError);

    jQuery.validator.addMethod('agecheck', function(value, element) {
        var nzDrinkingAge = 18;
        // Get user's age from their date of birth entered
        var age = moment().diff(moment(value, ACC.dateFormat), 'years');
        return this.optional(element) || age >= nzDrinkingAge;
      }, ACC.messageLabels.ageCheckError);
});