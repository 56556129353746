ACC.forgottenpassword = {

	_autoload: [
		"bindLink"
    ],

    bindLink: function(){
        $(document).on("click",".js-password-forgotten",function(e){
            e.preventDefault();

            ACC.colorbox.open({
                    href: $(this).data("link"),
                    width:"709px",
                    fixed: true,
                    top: false,
                    onOpen: function()
                    {
                        $('#validEmail').remove();
                    },
                    onComplete: function(){
                        $('form#lionForgottenPwdForm').ajaxForm({
                            success: function(data)
                            {
                                if ($(data).closest('#validEmail').length)
                                {

                                    if ($('#validEmail').length === 0)
                                    {
                                        $(".lion-forgotten-password").replaceWith(data);
                                        ACC.colorbox.resize();
                                    }
                                }
                                else
                                {
                                    $("#forgottenPwdForm .control-group").replaceWith($(data).find('.control-group'));
                                    ACC.colorbox.resize();
                                }
                            }
                        });
                    }
                }
            );
        });
    }

};