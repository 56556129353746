/*Babel ES6 transpiled!*/'use strict';

var enquiryOrder = {
  /* Mock data */
  // apis: {
  //   orderList: '/_ui/responsive/common/assets/order-list.json',
  //   orderDetails: '/_ui/responsive/common/assets/order-details.json',
  // },
  /* Actual API URLs */
  apis: {
    orderList: '/my-venue/my-enquiries/invoice-list',
    orderDetails: '/my-venue/my-enquiries/invoice-detail/'
  },

  orderSelect: document.querySelectorAll('#orderList'),
  productSelect: document.querySelectorAll('.js-enquiry-order-product'),
  orderContainer: document.querySelectorAll('.enquiry-level-2__return .form-group-container'),
  productContainer: document.querySelectorAll('.enquiry-level-3__return__product'),
  damagedProductContainer: document.querySelectorAll('.enquiry-level-4__damaged_stock__product'),
  missingProductContainer: document.querySelectorAll('.enquiry-level-4__missing_stock__product'),
  missingWholeDeliveryContainer: document.querySelectorAll('.enquiry-level-3__missing_stock__order'),

  orderProducts: [],

  /**
   * Code that runs on page load
   */
  init: function /*Babel ES6 transpiled!*/init(selected) {
    // Check if list already has an order list
    if ($('.order-list-' + selected + ' option').length <= 1) {
      /* Fetch order list */
      enquiryOrder.fetchOrders();
    }

    /* Add Event listener when order has been selected */
    var select = Array.from(enquiryOrder.orderSelect);
    select.forEach(function (item) {
      item.addEventListener('change', enquiryOrder.selectOrderHandler);
    });

    /* Add Event listener when product in an order is selected */
    document.addEventListener('change', function (e) {
      if (e.target && $(e.target).hasClass('js-enquiry-order-product')) {
        enquiryOrder.selectOrderProductHandler(e.target);
      }
    });
    document.addEventListener('keyup', function (e) {
      if (e.target && $(e.target).hasClass('enquiry__order-product-quantity')) {
        enquiryOrder.productQuantityHandler(e.target);
      }
    });
  },

  /**
   * Fetch a JSON list of orders in the past 30 days
   */
  fetchOrders: function /*Babel ES6 transpiled!*/fetchOrders() {
    var apiUrl = enquiryOrder.apis.orderList;

    // Will the default for order list return the results for user's b2bunit and 30 days ago?
    // If not, we need arguments for startDate and endDate
    var startdate = enquiryOrder.formatDateForServer(moment().subtract(30, 'days'));
    var enddate = enquiryOrder.formatDateForServer();

    // Show fetching message
    var select = Array.from(enquiryOrder.orderSelect);
    var message = /*Babel ES6 transpiled!*/ACC.sendEnquiry.fetchOrders + '...';

    select.forEach(function (item) {
      enquiryOrder.showMessage(message, 'orders', item.parentElement);
    });

    $.get(apiUrl, { startdate: startdate, enddate: enddate }, function (data) {
      /* Handle errors when no orders could be retrieved from order / user is not logged in */
      if (!Array.isArray(data) || data.length === 0) {
        console.error('No orders retrieved.');
        enquiryOrder.fetchOrderErrorHandler();
        return;
      }
      enquiryOrder.renderOrders(data);
    });
  },

  /**
   * Render the list of orders as a dropdown list
   */
  renderOrders: function /*Babel ES6 transpiled!*/renderOrders(data) {
    var select = Array.from(enquiryOrder.orderSelect);

    var options = data.map(function (item) {
      return (/*Babel ES6 transpiled!*/'<option value="' + item.invoiceNumber + '">' + item.invoiceNumber + ' - ' + item.orderNumber + ' - ' + enquiryOrder.formatDate(item.date) + '</option>'
      );
    }).join('');

    select.forEach(function (item) {
      item.insertAdjacentHTML('beforeend', options);
      // Hide any messages and display the dropdown
      enquiryOrder.hideMessage(item.parentElement);
      //if orders are retrieved make field validateable
      item.classList.remove("ignore-validation");
    });
  },

  /**
   * Display error message when orders could not be retrieved
   */
  fetchOrderErrorHandler: function /*Babel ES6 transpiled!*/fetchOrderErrorHandler() {
    var select = Array.from(enquiryOrder.orderSelect);
    var message = ACC.sendEnquiry.fetchOrdersErrorText;

    select.forEach(function (item) {
      enquiryOrder.showMessage(message, 'orders', item.parentElement);
    });
  },

  /**
   * Format dates for display
   */
  formatDate: function /*Babel ES6 transpiled!*/formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  },

  /**
   * Format dates server requests
   */
  formatDateForServer: function /*Babel ES6 transpiled!*/formatDateForServer(date) {
    return moment(date).format('YYYY-MM-DD');
  },

  /**
   * Handler function for when user selects an order
   */
  selectOrderHandler: function /*Babel ES6 transpiled!*/selectOrderHandler(e) {
    var orderId = e.currentTarget.value;
    var selected = $('option:selected', '#enquiryType1').val();
    var missingStock = $('option:selected', '#stockType').val();
    //Hide error messages if shown
    $('#send-enquiry__form-errorMessage').hide();
    $('.enquiry__order__msg').addClass("hidden");

    if (orderId !== '') {
      /* Display loading message */
      var message = /*Babel ES6 transpiled!*/ACC.sendEnquiry.fetchProducts + '...';

      if (selected === 'DAMAGED_STOCK') {
        enquiryOrder.showMessage(message, 'products', enquiryOrder.damagedProductContainer);
      } else if (selected === 'MISSING_STOCK') {
        enquiryOrder.showMessage(message, 'products', enquiryOrder.missingProductContainer);
      } else {
        enquiryOrder.showMessage(message, 'products', enquiryOrder.productContainer);
      }

      /* Reset product fields if any, and fetch products from the chosen order ID */
      enquiryOrder.resetOrderProducts();
      enquiryOrder.fetchOrderDetails(orderId);
    }
  },

  /**
   * Reset the products list when user selects an order
   */
  resetOrderProducts: function /*Babel ES6 transpiled!*/resetOrderProducts() {
    enquiryOrder.orderProducts = [];
    ACC.enquiry.productIndex = 0;
    $('.enquiry-level-3__return__product .product').remove();
  },

  /**
   * Fetch the order details which will contain the products list for that order
   * WE NEED THE FOLLOWING TO BE POSTED PER PRODUCT:
   * Product Name
   * Product EAN
   * Product Line number
   */
  fetchOrderDetails: function /*Babel ES6 transpiled!*/fetchOrderDetails(orderId) {
    var apiUrl = enquiryOrder.apis.orderDetails + /*Babel ES6 transpiled!*/('/' + orderId);

    $.get(apiUrl, function (data) {
      /* Handle errors when no products could be retrieved from order / user is not logged in */
      if ((!data.entries || data.entries.length === 0) && (!data.consignments || data.consignments.length === 0) && (!data.unconsignedEntries || data.unconsignedEntries.length === 0)) {
        console.error('No products retrieved.');
        enquiryOrder.fetchOrderDetailsErrorHandler();
        return;
      }
      var consignedEntries = [];
      if (data.entries) {
        for (var i = 0; i < data.entries.length; i++) {
          var consignment = data.entries[i];
          var tempCons = {
            name: consignment.product.name,
            ean: consignment.product.ean,
            lineNumber: consignment.itemNumber,
            quantity: consignment.qty,
            uom: consignment.uom
          };
          consignedEntries = consignedEntries.concat(tempCons);
        }
      }

      var products = consignedEntries;

      enquiryOrder.orderProducts = products;
      var missingStock = $('option:selected', '#stockType').val();
      var missingStockInfo = /*Babel ES6 transpiled!*/'' + ACC.sendEnquiry.fullStockMessage;
      var productsFieldset;
      var productContainer;
      if (missingStock === 'Z39') {
        enquiryOrder.showStockMessage(missingStockInfo, 'products', enquiryOrder.missingWholeDeliveryContainer);
        productContainer = $(enquiryOrder.missingProductContainer);
        productsFieldset = productContainer.find('.missing-products');
        productsFieldset.html(ACC.enquiryb2b.wholeDeliveryProductTemplate());
        productContainer.show();
        var header = document.getElementById('Acc-title');
        header.innerHTML = 'See Products in Invoice';
        /* If there was a message, clear it */
        enquiryOrder.hideMessage(productContainer);
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
              var header = document.getElementById('Acc-title');
              header.innerHTML = 'See Products in Invoice';
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
              var header = document.getElementById('Acc-title');
              header.innerHTML = 'Hide Products in Invoice';
            }
          });
        }
      } else {
        enquiryOrder.renderOrderProducts();
      }
    });
  },

  /**
   * Render the list of products from an order as a dropdown list
   */
  renderOrderProducts: function /*Babel ES6 transpiled!*/renderOrderProducts() {
    var selected = $('option:selected', '#enquiryType1').val();
    var productsFieldset;
    var productContainer;
    if (selected === "DAMAGED_STOCK") {
      productContainer = $(enquiryOrder.damagedProductContainer);
      productsFieldset = productContainer.find('.damaged-products');
    } else if (selected === 'MISSING_STOCK') {
      productContainer = $(enquiryOrder.missingProductContainer);
      productsFieldset = productContainer.find('.missing-products');
    } else {
      productContainer = $(enquiryOrder.productContainer);
      productsFieldset = productContainer.find('.products');
    }
    productsFieldset.html(ACC.enquiryb2b.productTemplate());
    productContainer.show();

    /* If there was a message, clear it */
    enquiryOrder.hideMessage(productContainer);
  },

  /**
   * Generate the <option> tags for the product dropdown list
   */
  orderProductDropdownOptions: function /*Babel ES6 transpiled!*/orderProductDropdownOptions() {
    var products = enquiryOrder.orderProducts;
    var placeholder = /*Babel ES6 transpiled!*/'<option value="" selected disabled>' + ACC.sendEnquiry.productName + '</option>';

    var options = products.map(function (product) {
      return (/*Babel ES6 transpiled!*/'<option value="' + product.ean + '">' + product.name + '</option>'
      );
    }).join('');

    options = placeholder + options;

    return options;
  },

  /**
   * Generate the  for the product list
   */
  orderProductList: function /*Babel ES6 transpiled!*/orderProductList() {
    var products = enquiryOrder.orderProducts;
    var placeholder = /*Babel ES6 transpiled!*/'<tr><th style="padding-left: 50px">Product</th><th style="padding-left: 50px;">Quantity</th><tr>';

    var options = products.map(function (product) {
      return (/*Babel ES6 transpiled!*/'<tr><td>' + product.name + '</td> <td style="padding-left: 70px">' + product.quantity + '</td><td>' + product.uom + '</td></tr>'
      );
    }).join('');

    options = placeholder + options;

    return options;
  },

  /**
   * Display error message when order details could not be retrieved
   */
  fetchOrderDetailsErrorHandler: function /*Babel ES6 transpiled!*/fetchOrderDetailsErrorHandler() {
    var message = ACC.sendEnquiry.fetchProductsErrorText;
    enquiryOrder.showMessage(message, 'products', enquiryOrder.productContainer);
  },

  /**
   * Handler function for when user selects a product in an order
   */
  selectOrderProductHandler: function /*Babel ES6 transpiled!*/selectOrderProductHandler(select) {
    // When a product list dropdown (.enquiry__order-product#productList{$}) is selected, populate hidden fields
    var product = select.value;
    var index = select.dataset.productIndex;
    enquiryOrder.populateOrderProductFields(product, index);
    enquiryOrder.setProductMaxQty(product, index);
  },

  /**
   * Handler function for when user inputs a product quantity without selecting a product
   */
  productQuantityHandler: function /*Babel ES6 transpiled!*/productQuantityHandler(product) {
    var index = product.dataset.productIndex;
    var productSelect = $('[name="enquiryProducts[${index}].product"]');
    var productQuantity = $('[name="enquiryProducts[${index}].productQuantity"]');

    if (!productSelect.val()) {
      productQuantity.val("");
    }
  },

  /**
   * Populate hidden fields of a product details
   */
  populateOrderProductFields: function /*Babel ES6 transpiled!*/populateOrderProductFields(product, index) {
    var fields = {
      name: $( /*Babel ES6 transpiled!*/'[name="enquiryProducts[' + index + '].productName"]'),
      ean: $( /*Babel ES6 transpiled!*/'[name="enquiryProducts[' + index + '].productEAN"]'),
      lineNumber: $( /*Babel ES6 transpiled!*/'[name="enquiryProducts[' + index + '].productLineNumber"]'),
      uom: $( /*Babel ES6 transpiled!*/'[name="enquiryProducts[' + index + '].productUom"]')
    };

    var selectedProduct = enquiryOrder.orderProducts.find(function (orderProduct) /*Babel ES6 transpiled!*/{
      return orderProduct.ean === product;
    });
    if (selectedProduct) {
      fields.name[0].value = selectedProduct.name;
      fields.ean[0].value = selectedProduct.ean;
      fields.lineNumber[0].value = selectedProduct.lineNumber;
      fields.uom[0].value = selectedProduct.uom;
    }
  },

  /**
   * Set the max for the product quantity field based on product quantity ordered
   */
  setProductMaxQty: function /*Babel ES6 transpiled!*/setProductMaxQty(product, index) {
    var productQty = $( /*Babel ES6 transpiled!*/'[name="enquiryProducts[' + index + '].productQuantity"]');

    var selectedProduct = enquiryOrder.orderProducts.find(function (orderProduct) /*Babel ES6 transpiled!*/{
      return orderProduct.ean === product;
    });
    if (selectedProduct) {
      productQty[0].max = selectedProduct.quantity;
    }
  },

  /*Babel ES6 transpiled!*/ /**
                             * Check for any duplicate products entered in the form.
                             */
  checkDuplicates: function checkDuplicates(fields) {
    var eans = fields.map(function (field) /*Babel ES6 transpiled!*/{
      return field.value;
    });

    var isUnique = eans.length === new Set(eans).size;

    if (!isUnique) {
      enquiryOrder.handleValidationError(fields);
      return false;
    }
    ACC.enquiryb2b.submitForm();
    return true;
  },
  /*Babel ES6 transpiled!*/

  /**
   * Display an orders/products fetching message and hide fields
   */
  showMessage: function showMessage(message, type, fieldContainer) {
    var msgContainer = $('.enquiry__order__msg');

    // Append the message
    msgContainer.html( /*Babel ES6 transpiled!*/'<span class="fetch-' + type + '">' + message + '</span>');

    // Show the message
    msgContainer.removeClass('hidden');

    // Hide the field/s
    if (fieldContainer) {
      $(fieldContainer).addClass('hidden');
    }
  },
  /*Babel ES6 transpiled!*/
  /**
   * Display missing stock message
   */
  showStockMessage: function showStockMessage(message, type, fieldContainer) {
    var msgContainer = $('.enquiry__order__stock_msg');

    // Append the message
    msgContainer.html( /*Babel ES6 transpiled!*/'<span class="fetch-' + type + '">' + message + '</span>');

    // Show the message
    msgContainer.removeClass('hidden');
  },
  /*Babel ES6 transpiled!*/

  /**
   * Hide a message and display fields
   */
  hideMessage: function hideMessage(fieldContainer) {
    var msgContainer = $('.enquiry__order__msg');

    // Empty the message container
    msgContainer.html('');

    // Hide the message
    msgContainer.addClass('hidden');

    // Show the field/s
    if (fieldContainer) {
      $(fieldContainer).removeClass('hidden');
    }
  },


  /**
   * When fields are invalid, display an error message.
   * When there are duplicate products in the group
   */
  handleValidationError: function /*Babel ES6 transpiled!*/handleValidationError(fields) {
    var fieldNames = fields.map(function (field) {
      return field.name.replace('productEAN', 'product');
    });
    fieldNames = Array.from(fieldNames);

    fieldNames.forEach(function (fieldName) {
      var field = $( /*Babel ES6 transpiled!*/'[name="' + fieldName + '"]');

      field.addClass('has-error');
      field.removeClass('is-valid');
      field.attr('aria-invalid', 'true');
      var fieldContainer = field.closest('.form-group');
      if (fieldContainer) {
        fieldContainer.addClass('has-error').removeClass('is-valid');
      }
    });

    var firstField = $( /*Babel ES6 transpiled!*/'[name="' + fieldNames[0] + '"]');
    var row = firstField.closest('.row');
    var msgContainer = row.find('.input-msg-group');
    var errorText = /*Babel ES6 transpiled!*/'<div id="product-error" class="input-msg input-error">' + ACC.sendEnquiry.orderProductErrorText + '</div>';
    $(msgContainer).html(errorText);
  }
};
