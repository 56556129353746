ACC.orderStatusCheck = {

    orderStatus:"",
    _autoload: [
        "bindOrderCheck"
    ],

    bindOrderCheck: function () {

        window.onload = new function () {
            var isOrderInprogressPage = $('#orderInProgressPage').length > 0;
            orderStatus = $('#orderInProgressPage').attr('data-orderStatus');
            var isOrderTimedout = orderStatus != 'OrderInProgress';
            if (isOrderInprogressPage && !isOrderTimedout)
            {
                setInterval(function(){
                     var cartCode = $('#orderInProgressPage').attr('data-cartCode');
                     $.ajax({
                         url: '/checkout/multi/orderinprogress/getorderstatus',
                         data:{cartCode: cartCode},
                         type: 'GET',
                         success: function(data){
                             orderStatus = data;
                             if(orderStatus != 'OrderInProgress'){
                                 window.location.reload();
                             }

                         }
                     })
                 }, 5000);
            }
        }
    }


};



