/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckoutCustomerDetails = function () {
  function CheckoutCustomerDetails() {
    _classCallCheck(this, CheckoutCustomerDetails);
  }

  _createClass(CheckoutCustomerDetails, [{
    key: 'bindDetailsFormValidation',
    value: function bindDetailsFormValidation() {
      $('#customerDetailsForm').validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          var $element = $(element);
          $element.closest('.form-group').addClass('has-error').removeClass('is-valid');
          $element.addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          var $element = $(element);
          $element.closest('.form-group').removeClass('has-error').addClass('is-valid');
          $element.removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          error.insertAfter(element.parent());
        },
        rules: {
          phone: { number: true }
        },
        messages: {
          phone: { number: ACC.messageLabels.phoneNumber }
        }
      });
    }
  }]);

  return CheckoutCustomerDetails;
}();

$(function () {
  var checkout = new CheckoutCustomerDetails();
  if ($('.customer-details__container').length > 0) {
    checkout.bindDetailsFormValidation();
  }
});
