/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FileUpload = function () {
    function /*Babel ES6 transpiled!*/FileUpload(form, sizeLimit, qtyLimit, fileExtLimit) {
        /*Babel ES6 transpiled!*/var required = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

        /*Babel ES6 transpiled!*/_classCallCheck(this, FileUpload);

        this.form = form;
        this.fileList = [];
        this.fileSizeLimit = sizeLimit;
        this.fileQtyLimit = qtyLimit;
        this.validFileExtensions = fileExtLimit;
        this.isRequired = required;
        this.fileUpload = /*Babel ES6 transpiled!*/form + ' .file-upload';
        this.fileUploadContainer = /*Babel ES6 transpiled!*/form + ' .js-file-upload';
        this.fileUploadList = '.js-file-upload-list';
        this.fileNameList = '.js-file-list';
        this.removeFileBtn = '.js-remove-file';
        this.uploadInputWrapper = '.upload-input-wrapper';
        this.uploadContainer = '.upload-container';
    }

    _createClass(FileUpload, [{
        key: 'init',
        value: function init() {
            $(this.fileUploadList).append(this.fileUploadTemplate());
            this.fileUploadHandler();
            this.deleteAttachmentHandler();
            this.deleteExistingAttachmentHandler();
            this.removeValidationState();
            this.toggleFileUploadMessage('all', 'hide');
        }
    }, {
        key: 'updateProperties',
        value: function updateProperties(required) {
            this.isRequired = required;
        }
    }, {
        key: 'fileUploadTemplate',
        value: function fileUploadTemplate() {
            var self = this;

            var fileUploadTemplate = $('.template__file-upload');
            if (fileUploadTemplate !== null) {
                return fileUploadTemplate.html();
            }
            return false;
        }
    }, {
        key: 'checkRequired',
        value: function checkRequired() {
            var self = this;

            if ($(self.fileUpload).prop('required')) {
                return true;
            }
            return false;
        }
    }, {
        key: 'checkFileSize',
        value: function checkFileSize(file) {
            var fileSizeLimit = this.fileSizeLimit;

            if (file.size > fileSizeLimit) {
                return false;
            }
            return true;
        }
    }, {
        key: 'checkFileQty',
        value: function checkFileQty() {
            // Check file limit
            var fileQtyLimit = this.fileQtyLimit,
                fileList = this.fileList;

            if (fileList.length > fileQtyLimit) {
                return false;
            }
            return true;
        }
    }, {
        key: 'checkExistingFile',
        value: function checkExistingFile(file) {
            var fileList = this.fileList;
            var arrayLength = fileList.length;

            if (arrayLength > 0) {
                for (var i = 0; i < arrayLength; i++) {
                    if (fileList[i] == file.name) {
                        return false;
                    }
                }
            }
            return true;
        }
    }, {
        key: 'checkFileFormat',
        value: function checkFileFormat(file) {
            var validFileExtensions = this.validFileExtensions;

            for (var i = 0; i < validFileExtensions.length; i++) {
                var extension = validFileExtensions[i];
                if (file.name.substr(file.name.length - extension.length, extension.length).toLowerCase() == extension.toLowerCase()) {
                    return true;
                }
            }
            return false;
        }
    }, {
        key: 'fileUploadHandler',
        value: function fileUploadHandler() {
            var self = this;
            $(self.form).on('change', '.file-upload', function () {
                if (this.files.length === 0) {
                    return false;
                }
                var file = this.files[0];

                if (self.checkFileQty() && self.checkExistingFile(file)) {

                    self.toggleFileUploadMessage('all', 'hide');

                    if (!self.checkFileSize(file)) {
                        self.toggleFileUploadMessage('size', 'show');
                        self.toggleErrorState();
                        this.value = "";
                        return false;
                    }

                    if (!self.checkFileFormat(file)) {
                        self.toggleFileUploadMessage('format', 'show');
                        self.toggleErrorState();
                        this.value = "";
                        return false;
                    }

                    if (self.checkFileSize(file) && self.checkFileFormat(file)) {
                        // If qty and size is valid, add to file list
                        self.fileList.push(file.name);

                        self.toggleValidState();

                        // Add a label with the file name that can remove the file.
                        $(self.fileNameList).append(self.fileNameLabel(file.name));

                        // Hide current input
                        var fileUploadContainer = $(this).closest(self.fileUploadContainer);
                        fileUploadContainer.addClass('hidden');

                        // If file limit is reached...
                        if (self.fileList.length === self.fileQtyLimit) {
                            // Display limit reached message
                            self.toggleFileUploadMessage('limit', 'show');
                            $(self.uploadInputWrapper).addClass('is-disabled');
                        } else {
                            // Clone input so that user can upload another file
                            $(self.fileUploadTemplate()).appendTo(fileUploadContainer.parent());
                        }
                    } else {
                        this.value = "";
                        self.toggleErrorState();
                    }
                } else {
                    this.value = "";
                }
            });
        }
    }, {
        key: 'toggleFileUploadMessage',
        value: function toggleFileUploadMessage(message, toggleType) {
            var messageElement;
            if (message === 'required') {
                messageElement = $('#uploadAttachmentErrorRequired');
            } else if (message === 'limit') {
                messageElement = $('#uploadAttachmentMessageLimit');
            } else if (message === 'size') {
                messageElement = $('#uploadAttachmentErrorSize');
            } else if (message === 'format') {
                messageElement = $('#uploadAttachmentErrorFormat');
            } else {
                messageElement = $('.upload-attachment-msg');
            }

            messageElement[toggleType]();
        }
    }, {
        key: 'toggleErrorState',
        value: function toggleErrorState() {
            // If .upload-container is wrapped around by .form-group, add the error class in .form-group.
            // Else, add the error class in .upload-container.
            if ($(this.uploadContainer).parent('.form-group').length > 0) {
                $(this.uploadContainer).parent('.form-group').addClass('has-error').removeClass('is-valid');
            } else {
                $(this.uploadContainer).addClass('has-error').removeClass('is-valid');
            }
        }
    }, {
        key: 'toggleValidState',
        value: function toggleValidState() {
            // If .upload-container is wrapped around by .form-group, add the valid class in .form-group.
            // Else, add the valid class in .upload-container.
            if ($(this.uploadContainer).parent('.form-group').length > 0) {
                $(this.uploadContainer).parent('.form-group').addClass('is-valid').removeClass('has-error');
            } else {
                $(this.uploadContainer).addClass('is-valid').removeClass('has-error');
            }
        }
    }, {
        key: 'removeValidationState',
        value: function removeValidationState() {
            // If .upload-container is wrapped around by .form-group, remove the valid and error class in .form-group.
            // Else, remove the valid class in .upload-container.
            if ($(this.uploadContainer).parent('.form-group').length > 0) {
                $(this.uploadContainer).parent('.form-group').removeClass('is-valid').removeClass('has-error');
            } else {
                $(this.uploadContainer).removeClass('is-valid').removeClass('has-error');
            }
        }
    }, {
        key: 'fileNameLabel',
        value: function fileNameLabel(fileName) {
            return (/*Babel ES6 transpiled!*/'<div class="file-list--item">\n                    ' + fileName + '\n                    <button type="button" class="btn btn--no-style js-remove-file" data-file-name="' + fileName + '">\n                        <svg class="icon icon--remove"><use xlink:href="#close-icon" /></svg>\n                    </button>\n                </div>'
            );
        }
    }, {
        key: 'deleteAttachmentHandler',
        value: function deleteAttachmentHandler() {
            var self = this;
            $(self.form).on('click', '.js-remove-file', function () {
                var removeButton = $(this),
                    fileName = removeButton.data('fileName');

                removeButton.parent().remove();

                self.fileList.splice(self.fileList.indexOf(fileName), 1);
                self.removeFileInput(fileName);
                if (this.isRequired) {
                    $('.file-upload').first().prop('required', true);
                }

                // If less than qtyLimit, show the upload input again.
                if (self.checkFileQty() && $(self.fileUploadContainer + ':not(.hidden)').length === 0) {
                    $(self.fileUploadList).append(self.fileUploadTemplate());
                    self.toggleFileUploadMessage('limit', 'hide');
                    $(self.uploadInputWrapper).removeClass('is-disabled');
                }

                if (self.fileList.length === 0) {
                    self.removeValidationState();
                    if (self.required) {
                        self.toggleValidState();
                    }
                }
            });
        }
    }, {
        key: 'removeFileInput',
        value: function removeFileInput(fileName) {
            var self = this;
            $('.file-upload').each(function (index) {
                if (this.files[0] && fileName === this.files[0].name) {
                    $(this).closest(self.fileUploadContainer).remove();
                    if (index === 0 && self.isRequired) {
                        $('.file-upload').first().prop('required', true);
                    }
                    return false;
                }
            });
        }
    }, {
        key: 'deleteExistingAttachmentHandler',
        value: function deleteExistingAttachmentHandler(fileName) {
            var self = this;
            $(self.form).on('click', '.js-remove-file-existing', function (e) {
                e.preventDefault();
                var removeButton = $(this),
                    fileName = removeButton.data('fileName'),
                    url = removeButton.attr('href');

                // AJAX
                $.get(url, function () {
                    removeButton.parent().remove();
                    self.fileList.splice(self.fileList.indexOf(fileName), 1);

                    // Show and enable the upload input again.
                    $(self.fileUploadList).append(self.fileUploadTemplate());
                    self.toggleFileUploadMessage('limit', 'hide');
                    $(self.uploadInputWrapper).removeClass('is-disabled');

                    // Increment the maximumFiles input value so that backend expects the amount of new potential files being uploaded
                    var maxFilesInput = $('[name="maximumFiles"]');
                    maxFilesInput.val(parseInt(maxFilesInput.val()) + 1);
                }).fail(function () {
                    console.error(ACC.messageLabels.fileDeletionError);
                });
            });
        }
    }, {
        key: 'resetFileUpload',
        value: function resetFileUpload() {
            var self = this;

            if (self.fileList.length === self.fileQtyLimit) {
                $(self.fileUploadList).append(self.fileUploadTemplate());
            }
            self.fileList = [];
            self.toggleFileUploadMessage('all', 'hide');
            $(self.uploadInputWrapper).removeClass('is-disabled');
        }
    }]);

    return FileUpload;
}();
