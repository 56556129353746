/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var form = document.getElementById('payment-form');
var docHeight = $(document).height();
var isNZSite = ACC.config.isNzSite === "true";
var paymentButton = '.js-btn-add-payment';
var publishKey = $('#publishKey') && $('#publishKey').length ? $('#publishKey').val() : '';
var supplierBusinessCode = $('#supplierBusinessCode') && $('#supplierBusinessCode').length ? $('#supplierBusinessCode').val() : '';

var stripe = publishKey && isNZSite ? Stripe(publishKey) : '';
var elements = stripe && isNZSite ? stripe.elements() : '';
var card = /*Babel ES6 transpiled!*/void 0;
var style = {
  base: {
    fontSize: '16px',
    color: '#3e454a',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: 'rgba(62, 69, 74, 0.5)'
    }
  }
};
var westpac = {
  iframe: {
    "font-size": "14px",
    "line-height": "24px",
    "min-height": "255px",
    "width": "100%",
    "background-color": "white"
  },
  inputStyle: {
    "height": "60px",
    "margin-top": "-15px",
    "margin-bottom": "5px",
    "padding": "0px 12px",
    "font-size": "20px",
    "border": "solid 1px #e7e6e6",
    "border-radius": "5px",
    "color": "#3e454a",
    "box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.2)"
  },
  inputStyleFocus: {
    "border-color": "#f28900",
    "outline": "0"
  },
  inputStyleMobile: {
    "height": "50px",
    "font-size": "16px"
  },
  labelStyle: {
    "color": "transparent"
  },
  bodyStyle: {
    "font-family": "'sofia-pro', sans-serif",
    "font-size": "25px",
    "line-height": "10px",
    "color": "#69687a"
  }
};

/*Babel ES6 transpiled!*/var PaymentMethods = function () {
  function PaymentMethods() {
    _classCallCheck(this, PaymentMethods);
  }

  _createClass(PaymentMethods, [{
    key: 'init',
    value: function init() {
      this.bindRemovePaymentMethod();
      this.bindAddPaymentMethod();
      this.initializeHintDropdown();
    }
  }, {
    key: 'bindRemovePaymentMethod',
    value: function bindRemovePaymentMethod() {
      $(document).on("click", ".js-remove-payment", function (e) {
        e.preventDefault();
        var addressId = $(this).data("payment-method-id");
        var removeUrl = $(this).data("remove-url");
        ACC.colorbox.open({
          href: $(this).data("link"),
          width: "709px",
          height: false,
          fixed: true,
          top: false,
          className: 'remove-payment-container',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            $('#remove-payment-method').removeClass('hide');
            $('#payment-form').addClass('hide');
            $(document).on('click', '.js-payment-method-remove', function () {
              window.location.replace(removeUrl);
            });

            $(document).on('click', '.js-payment-method-cancel', function () {
              ACC.colorbox.close();
            });
            $.colorbox.resize();
          }
        });
      });
    }
  }, {
    key: 'bindAddPaymentMethod',
    value: function bindAddPaymentMethod() {
      var that = this;
      $(document).on("click", ".js-add-payment", function (e) {
        e.preventDefault();
        ACC.colorbox.open({
          href: $(this).data("link"),
          width: "709px",
          height: false,
          fixed: false,
          top: false,
          escKey: false,
          overlayClose: false,
          className: 'add-payment-container',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            $("#remove-payment-method").addClass("hide");
            if (isNZSite) {
              that.initializeStripeFormSubmit();
            } else {
              that.initializeWestpacFormSubmit(that);
              $.colorbox.resize();
              that.modalPosition(docHeight);

              $("#payment-saved").on('blur', function () {
                $.colorbox.resize();
                that.modalPosition(docHeight);
              });
            }

            that.bindFormEvents();
            $(document).on("click", ".js-add-payment-cancel", function () {
              ACC.colorbox.close();
            });
            $.colorbox.resize();
          }
        });
      });
    }
  }, {
    key: 'initializeHintDropdown',
    value: function initializeHintDropdown() {
      /*Babel ES6 transpiled!*/var _this = this;

      $(document).on('click', '.cvv-hint', function () {
        $('.hint-arrow').toggleClass('icon--arrow-right').toggleClass('icon--arrow-down');
        $('.cvv-hint__container').toggleClass('hide');
        $.colorbox.resize();
        /*Babel ES6 transpiled!*/_this.modalPosition(docHeight);
      });
    }
  }, {
    key: 'bindFormEvents',
    value: function bindFormEvents() {
      this.initializeForm();
      this.bindFormValidation();
    }
  }, {
    key: 'initializeForm',
    value: function initializeForm() {
      $('#payment-form').find('input').each(function () {
        $(this).trigger('focus');
        $(this).trigger('blur');
      });
    }
  }, {
    key: 'bindFormValidation',
    value: function bindFormValidation() {
      var that = this;
      $('#payment-form').validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          that.toggleErrorClassesForElement(element, true);
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          that.toggleErrorClassesForElement(element, false);
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error modal-error-message',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          error.insertAfter(element.parent());
          $.colorbox.resize();
        },
        rules: {
          firstName: { lionname: true }
        }
      });
    }
  }, {
    key: 'toggleErrorClassesForElement',
    value: function toggleErrorClassesForElement(element, addClasses) {
      var $element = $(element);
      if (addClasses) {
        $element.closest('.form-group').addClass('has-error');
        $element.addClass('has-error').removeClass('is-valid');
      } else {
        $element.closest('.form-group').removeClass('has-error');
        $element.removeClass('has-error').addClass('is-valid');
      }
      $.colorbox.resize();
    }
  }, {
    key: 'modalPosition',
    value: function modalPosition(documentHeight) {
      var colorboxHeight = $('#colorbox').height();
      var colorboxTop = parseInt(document.getElementById('colorbox').style.top);
      var maxTop = documentHeight - colorboxHeight - 50;
      if (colorboxTop > maxTop) {
        $('#colorbox').css('top', maxTop);
      }
    }

    /** NZ STRIPE METHODS START */

  }, {
    key: 'initializeStripeFormSubmit',
    value: function initializeStripeFormSubmit() {
      var ownerEmail = $('[name="billingAddress.customerEmailAddress"]').val();
      var ownerName = $('[name="billingAddress.customerName"]').val();
      if (!card) {
        card = elements.create('card', { style: style, hidePostalCode: true });
      }
      // mount the card element into the DOM
      card.mount('#card-element');

      // add event listener for credit card errors
      card.addEventListener('change', this.handleCreditCardErrors.bind(this));

      var that = this;
      $(document).on('submit', '#payment-form', function (event) {
        // show loading spinner on submit button
        ACC.loader.showLoadingOnButton(paymentButton);
        event.preventDefault();
        // create owner info to pass to stripe
        var ownerInfo = {
          owner: {
            name: ownerName,
            address: {},
            email: ownerEmail
          }
        };
        // create the card source with stripe
        stripe.createSource(card, ownerInfo).then(function (result) {
          if (result.error) {
            // Inform the user if there was an error
            // hide loading spinner from the button
            ACC.loader.hideLoadingOnButton(paymentButton);
            $('#payment-error').html(result.error.message).removeClass('hide');
            $.colorbox.resize();
          } else {
            // Send the source to server
            that.sourceTokenHandler(result.source.id);
          }
        });
      });
    }

    /**
    * Handles the in-line credit card errors
    * 
    * @param {any} event 
    * @memberof PaymentMethods
    */

  }, {
    key: 'handleCreditCardErrors',
    value: function handleCreditCardErrors(event) {
      var element = '.stripe-card';
      if (event.error) {
        $('#cc-error').html(event.error.message).removeClass('hide');
        this.toggleErrorClassesForElement(element, true);
      } else {
        if (!$('#cc-error').hasClass('hide')) {
          $('#cc-error').html('').addClass('hide');
          this.toggleErrorClassesForElement(element, false);
        }
      }
    }

    /**
     * Handles the source object returned by stripe
     * 
     * @param {any} source 
     * @memberof PaymentMethods
     */

  }, {
    key: 'sourceTokenHandler',
    value: function sourceTokenHandler(sourceToken) {
      /*Babel ES6 transpiled!*/var creditCard = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      // Insert the source ID into the form so it gets submitted to the server
      var form = document.getElementById('payment-form');
      var sourceTokenInput = document.createElement('input');
      sourceTokenInput.setAttribute('type', 'hidden');
      sourceTokenInput.setAttribute('name', 'sourceToken');
      sourceTokenInput.setAttribute('value', sourceToken);
      form.appendChild(sourceTokenInput);

      if (creditCard != false) {
        /*Babel ES6 transpiled!*/var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for ( /*Babel ES6 transpiled!*/var _iterator = Object.keys(creditCard)[Symbol.iterator](), _step; /*Babel ES6 transpiled!*/!(_iteratorNormalCompletion = (_step = _iterator.next()).done); /*Babel ES6 transpiled!*/_iteratorNormalCompletion = true) {
            /*Babel ES6 transpiled!*/var key = _step.value;

            var input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', creditCard[key]);
            form.appendChild(input);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      // Submit the form
      this.paymentFormSubmit();
    }
    /** NZ STRIPE METHODS END */

    /** AU WESTPAC METHODS START */

  }, {
    key: 'initializeWestpacFormSubmit',
    value: function initializeWestpacFormSubmit(that) {
      QuickstreamAPI.init({
        publishableApiKey: publishKey
      });
      var trustedFrame;
      var options = {
        config: {
          supplierBusinessCode: supplierBusinessCode
        },
        iframe: {
          "width": "100%",
          "height": "355px",
          "style": westpac.iframe
        },
        showAcceptedCards: false,
        cardholderName: {
          style: westpac.inputStyle,
          placeholder: ACC.paymentMethods.cardName
        },
        cardNumber: {
          style: westpac.inputStyle,
          placeholder: ACC.paymentMethods.cardNumber
        },
        expiryDateMonth: {
          style: westpac.inputStyle
        },
        expiryDateYear: {
          style: westpac.inputStyle
        },
        cvn: {
          showHelp: false,
          style: westpac.inputStyle,
          placeholder: ACC.paymentMethods.cvc
        },
        labels: {
          style: westpac.labelStyle
        },
        body: {
          style: westpac.bodyStyle
        }
      };

      QuickstreamAPI.creditCards.createTrustedFrame(options, function (errors, data) {
        trustedFrame = data.trustedFrame;

        $.colorbox.resize();
        that.modalPosition(docHeight);

        //Handles styling on focus & blur on form fields
        that.westpacStyleHandler(trustedFrame);
      });

      $(document).on('submit', '#payment-form', function (event) {
        ACC.loader.showLoadingOnButton(paymentButton);
        event.preventDefault();

        trustedFrame.submitForm(function (errors, data) {
          if (errors) {
            ACC.loader.hideLoadingOnButton(paymentButton);
          } else {
            var token = data.singleUseToken.singleUseTokenId;
            var creditCard = data.singleUseToken.creditCard;

            that.sourceTokenHandler(token, creditCard);
          }
        });
      });
    }
  }, {
    key: 'westpacStyleHandler',
    value: function westpacStyleHandler(trustedFrame) {
      var westpacInputFields = ["cardholderName", "cardNumber", "expiryDateMonth", "expiryDateYear", "cvn"];
      var that = this;
      westpacInputFields.forEach(function (elem) {
        trustedFrame.setEventHandler(elem, "focus", function (event) {
          trustedFrame.changeStyle(elem, westpac.inputStyleFocus, function (errors, data) {});
          if (navigator.userAgent.match(/Android/i)) {
            $('#colorbox').addClass('device-android');
            $('#colorbox').addClass('device-android-input');
          }
          $.colorbox.resize();
          that.modalPosition(docHeight);
        });
        trustedFrame.setEventHandler(elem, "blur", function (event) {
          trustedFrame.changeStyle(elem, westpac.inputStyle, function (errors, data) {});
          if (navigator.userAgent.match(/Android/i)) {
            $('#colorbox').removeClass('device-android-input');
          }
          $.colorbox.resize();
          that.modalPosition(docHeight);
        });
      });

      //MOBILE STYLING
      if (navigator.userAgent.match(/Mobi/)) {
        westpacInputFields.forEach(function (elem) {
          trustedFrame.changeStyle(elem, westpac.inputStyleMobile, function (errors, data) {});
        });
        $.colorbox.resize();
        that.modalPosition(docHeight);
      }
    }

    /**AU WESTPAC METHODS END */

    /**
     * Custom form submit for payment form to handle backend validation errors
     * 
     * @memberof PaymentMethods
     */

  }, {
    key: 'paymentFormSubmit',
    value: function paymentFormSubmit() {
      var form = $('#payment-form');
      var post_url = $(form).attr("action"); //get form action url
      var return_url = $(form).attr("returnurl"); //get form action url
      var request_method = $(form).attr("method"); //get form GET/POST method
      var form_data = $(form).serialize(); //Encode form elements for submission
      var sectionToReplace = '#payment-form';
      $.ajax({
        url: post_url,
        type: request_method,
        data: form_data
      }).done(function (response) {
        if ($(response).find('.form-error-message').length > 0) {
          if (isNZSite) {
            $(sectionToReplace).replaceWith($(response));
            that.initializeStripeFormSubmit();
            that.bindFormEvents();
          } else {
            that.initializeWestpacFormSubmit();
          }
          $.colorbox.resize();
        } else {
          window.location.replace(return_url);
        }
      });
    }
  }]);

  return PaymentMethods;
}();

$(function () {
  var paymentMethods = new PaymentMethods();
  if ($('.payment-methods').length > 0) {
    paymentMethods.init();
  }
});
