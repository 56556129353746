ACC.autocomplete = {

    query:"",
	_autoload: [
		"bindSearchAutocomplete",
        "bindDisableSearch",
		"bindDisablePageNotFoundSearch"
	],

	bindSearchAutocomplete: function ()
	{
		// extend the default autocomplete widget, to solve issue on multiple instances of the searchbox component
		$.widget( "custom.yautocomplete", $.ui.autocomplete, {
			_create:function(){
				
				// get instance specific options form the html data attr
				var option = this.element.data("options");
				// set the options to the widget
				this._setOptions({
					minLength: option.minCharactersBeforeRequest,
					displayProductImages: option.displayProductImages,
					delay: option.waitTimeBeforeRequest,
					autocompleteUrl: option.autocompleteUrl,
					source: this.source
				});
				
				// call the _super()
				$.ui.autocomplete.prototype._create.call(this);
				
			},
			options:{
				cache:{}, // init cache per instance
				focus: function (){return false;} // prevent textfield value replacement on item focus
			},
			_renderItem : function (ul, item){
				var isB2b = ACC.config.isB2bCustomer;
				if (item.type == "autoSuggestion"){
					var renderHtml = "<a href='"+ item.url + "' ><div class='name'>" + item.value + "</div></a>";
					return $("<li>")
							.data("item.autocomplete", item)
							.append(renderHtml)
							.appendTo(ul);
				}
				else if (item.type == "productResult"){

					var renderHtml = "<a href='" + item.url + "' >";

					if (item.image != null){
						renderHtml += "<div class='search-box__product--image pull-left'><img src='" + item.image + "'  /></div>";
					} else if (ACC.noThumbnailImageFoundUrl) {
						renderHtml += "<div class='search-box__product--image pull-left'><img src='" + ACC.noThumbnailImageFoundUrl + "'  /></div>";
					}
					// Comment out for future reference TO DO: NGP2HYBRIS-2659
					//renderHtml +=		"<div class='search-box__product--detail pull-left'><span class='product--detail__text'><strong>" + item.value + "</strong>" + " " + item.value + "</span><span class='product--detail__text-normal'>" + item.value +"</span></div>";
					renderHtml +=	"<div class='search-box__product--detail pull-left'><span class='product--detail__text'><strong>" + item.value + "</strong></span></div>";
					renderHtml += 	"</a>";
					if (isB2b === 'true'){
					return $("<li class='b2bElement'>").data("item.autocomplete", item).append(renderHtml).appendTo(ul);
					}
					else {
						return $("<li>").data("item.autocomplete", item).append(renderHtml).appendTo(ul);
					}
				}
				else if (item.type == "displayAll"){
					var searchTerm = encodeURIComponent(ACC.autocomplete.query).replace(/ /g, '+').replace(/'/g, "%27");
				    var render = "<a class='btn btn-primary btn--search-all' href='/search/?text="+searchTerm+"'>Search All</a>";
					var q=$('#ui-id-1 li').length;
					if (isB2b === 'true' && q !== 0){
						return $("<li style='position:fixed;top:545px;width:340px;'>").data("item.autocomplete", item).append(render).appendTo(ul);
					}
					else {
						return $("<li>").data("item.autocomplete", item).append(render).appendTo(ul);
					}
				}
			},
			_resizeMenu: function(){
				var B2b = ACC.config.isB2bCustomer;
				var id=$('#ui-id-1 li').length;
				if (B2b === 'true' && id !== 1){
				this.menu.element.outerHeight(475);
				this.menu.element.css({
					"overflow-y": "scroll",
					"overflow-x": "hidden",
					"padding-right": "20px"
				});
			}
			
			},
			source: function (request, response)
			{
				var self=this;
				var term = request.term;
				ACC.autocomplete.query = term;
				if (term in self.options.cache)
				{
					return response(self.options.cache[term]);
				}

				$.getJSON(self.options.autocompleteUrl, {term: request.term}, function (data)
				{
					var autoSearchData = [];
					/*if(data.suggestions != null){
						$.each(data.suggestions, function (i, obj)
						{
							autoSearchData.push({
								value: obj.term,
								url: ACC.config.encodedContextPath + "/search?text=" + obj.term,
								type: "autoSuggestion"
							});
						});
					}*/
					if(data.products != null){
						$.each(data.products, function (i, obj)
						{
							autoSearchData.push({
								value: ACC.sanitizer.sanitize(obj.name),
								code: obj.code,
								desc: ACC.sanitizer.sanitize(obj.description),
								manufacturer: ACC.sanitizer.sanitize(obj.manufacturer),
								url:  ACC.config.encodedContextPath + obj.url,
								price: (obj.price!=null) ? obj.price.formattedValue : null,
								type: "productResult",
								image: (obj.images!=null && self.options.displayProductImages) ? obj.images[0].url : null // prevent errors if obj.images = null
							});
						});
						autoSearchData.push({
                            type: "displayAll"
                        });
					}
					self.options.cache[term] = autoSearchData;
					return response(autoSearchData);
				});
			}

		});

	
		$search = $(".js-site-search-input");
		if($search.length>0){
			$search.yautocomplete()
		}

        $pageNotFoundSearch = $(".js-page-not-found-search");
        if($pageNotFoundSearch.length){
            $pageNotFoundSearch.yautocomplete()
        }

	},

	bindDisableSearch: function ()
    {
        $('#js-site-search-input').keyup(function(){
        	$('#js-site-search-input').val($('#js-site-search-input').val().replace(/^\s+/gm,''));
            $('.js_search_button').prop('disabled', this.value == "" ? true : false);
        })
    },

    bindDisablePageNotFoundSearch: function ()
    {
        $('#js-page-not-found-search').keyup(function(){
        	$('#js-page-not-found-search').val($('#js-page-not-found-search').val().replace(/^\s+/gm,''));
            $('.js_search_button').prop('disabled', this.value == "" ? true : false);
        })
    }
};