/*Babel ES6 transpiled!*/"use strict";

ACC.enquiryb2b = {

    _autoload: [["initForm", $('.send-enquiry--b2b').length != 0], ["validateEnquiryForm", $('.send-enquiry--b2b').length != 0], ["onEnquiryTypeChange", $('.send-enquiry--b2b').length != 0], ["addProduct", $('.send-enquiry--b2b').length != 0], ["removeProduct", $('.send-enquiry--b2b').length != 0], ["removeAuEnquiryType", $('.send-enquiry--b2b').length != 0], ["removeNzEnquiryType", $('.send-enquiry--b2b').length != 0], ["fileUploadInit", $('.send-enquiry__form').length != 0]],
    form: '#enquiryForm',
    fileUploadInput: '#enquiryForm .js-file-upload',
    fileSizeLimit: 5242880, // 5 MB
    fileQtyLimit: 5,
    validFileExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.pdf'],
    validator: '',
    fileUploadContainer: '.send-enquiry-upload-container',
    fileUpload: '',

    /**
     * Initialise some form events on load
     */
    initForm: function /*Babel ES6 transpiled!*/initForm() {
        ACC.enquiryb2b.initDateTimePicker();

        /* Add an event listener for dropdowns to toggle colour based on if it has a value or not */
        var selects = Array.from($('.send-enquiry--b2b select'));

        selects.forEach(function (select) {
            ACC.enquiryb2b.toggleSelectColour(select);
        });

        document.addEventListener('change', function (e) {
            if (e.target && e.target.type === 'select-one' && e.target.form.id === 'enquiryForm') {
                ACC.enquiryb2b.toggleSelectHandler(e.target);
            }
        });

        /* Initialise product search and order search functionalities */
        enquirySearch.init();

        /* Disable all enquiry subtype fields */
        var fieldsets = Array.from($('.enquiry-level--2'));
        fieldsets.forEach(function (fieldset) {
            var fields = Array.from($(fieldset).find('select, input, textarea'));
            fields.forEach(function (field) {
                field.disabled = true;
            });
        });
        ACC.enquiryb2b.fileUpload = new FileUpload(ACC.enquiryb2b.form, ACC.enquiryb2b.fileSizeLimit, ACC.enquiryb2b.fileQtyLimit, ACC.enquiryb2b.validFileExtensions);
    },

    /**
    * Initialise jQuery Validator on the form
    * https://jqueryvalidation.org/documentation/
    */
    validateEnquiryForm: function /*Babel ES6 transpiled!*/validateEnquiryForm() {
        var that = this;
        $.validator.messages.required = /*Babel ES6 transpiled!*/"" + ACC.sendEnquiry.errorText;
        $('#enquiryForm').validate({
            onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
                // Eager validation
                this.element(element);

                var fileUploadContainer = $(element).hasClass('file-upload');
                var attachmentError = Array.from(document.querySelectorAll('.uploadAttachment-error'));
                //Removes duplicate required error messages from the upload attachment container
                if (fileUploadContainer) {
                    attachmentError.forEach(function (message, index) {
                        if (index === 0) {
                            return false;
                        } else {
                            message.remove();
                        }
                    });
                }
            },
            onkeyup: function /*Babel ES6 transpiled!*/onkeyup(element) {
                // Eager validation
                // Apply only on specific element with keyup event error msg
                if ($(element).attr('data-element') == 'qty-max') {
                    this.element(element);
                }
            },
            messages: {
                message: $('#enquiryForm label[for="message"]').data('error'),
                enquiryType1: $('#enquiryForm [name="enquiryType1"]').data('error'),
                enquiryType2: ACC.sendEnquiry.subEnquiryErrorText,
                enquirySubType: ACC.sendEnquiry.subEnquiryErrorText,
                enquiryIssueType1: ACC.sendEnquiry.issueTypeErrorText,
                enquiryIssueType2: ACC.sendEnquiry.issueTypeErrorText,
                productName: ACC.sendEnquiry.productErrorText,
                productCode: ACC.sendEnquiry.productSearchErrorText,
                productQuantity: ACC.sendEnquiry.productQuantityErrorText,
                orderNumber: ACC.sendEnquiry.orderErrorText,
                uploadAttachment: ACC.sendEnquiry.uploadAttachmentRequired
            },
            ignore: '.ignore-validation',
            highlight: function /*Babel ES6 transpiled!*/highlight(element) {
                $(element).closest('.form-group').addClass('has-error').removeClass('is-valid');
                $(element).addClass('has-error').removeClass('is-valid');
            },
            unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
                $(element).closest('.form-group').removeClass('has-error').addClass('is-valid');
                $(element).removeClass('has-error').addClass('is-valid');
            },
            errorElement: 'div',
            errorClass: 'input-msg input-error',
            errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
                var row = $(element).closest('.row');
                var msgGroup = $(row).find('.input-msg-group');
                var errorID = $(error).attr('id');

                $(error).addClass(errorID);
                $(msgGroup).prepend(error);
            },
            errorContainer: '#send-enquiry__form-error',
            submitHandler: function /*Babel ES6 transpiled!*/submitHandler(form) {
                /**
                 * If the form being submitted contains a product search field,
                 * there is a hidden product code field that needs to be validated.
                 * 1. Check that the form contains a product search.
                 *  a) If it does, get the product name and code field values and pass it on for validation.
                 * 2. Check that the form contains order product fields.
                 *  a) If it does, get the product EAN to pass it on for validation.
                 * 3. If it doesn't have either, just submit the form.
                 */
                var formFields = $(form).serializeArray();
                var productCode = formFields.find(function (field) /*Babel ES6 transpiled!*/{
                    return field.name === 'productCode';
                });
                if (productCode !== undefined) {
                    var productName = formFields.find(function (field) /*Babel ES6 transpiled!*/{
                        return field.name === 'productName';
                    });
                    enquirySearch.validateProduct(productName.value, productCode.value);
                    return false;
                }

                var orderProducts = formFields.filter(function (field) /*Babel ES6 transpiled!*/{
                    return field.name.includes('productEAN');
                });
                if (orderProducts.length !== 0) {
                    enquiryOrder.checkDuplicates(orderProducts);
                    return false;
                }
                if (!$('.enquiry__order__msg').hasClass('hidden')) {
                    $('#send-enquiry__form-errorMessage').show();
                    return false;
                }

                form.submit();
            }
        });

        ACC.enquiryb2b.validator = $('#enquiryForm').validate();

        // Customize adding classes when enquiry submit is clicked
        $(document).on("click", "#sendEnquiryBtn", function () {
            setTimeout(function () {
                $('.enquiry__product-code.has-error').siblings('.enquiry__product-search').addClass('has-error');
                $('.enquiry__product-code.has-error').closest('.form-group').addClass('has-error');

                if ($('.enquiry__product-code.has-error').closest('.row').find('#productSearch-error').is(':visible')) {
                    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').addClass('hidden');
                    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').removeClass('visible');
                } else {
                    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').addClass('visible');
                    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').removeClass('hidden');
                }
            }, 100);

            var attachmentError = Array.from(document.querySelectorAll('.uploadAttachment-error'));
            if (attachmentError.length > 1) {
                attachmentError.forEach(function (message, index) {
                    if (index === 0) {
                        return false;
                    } else {
                        message.remove();
                    }
                });
            }
        });

        $(document).on("click", ".js-remove-file", function () {
            if ($('.file-upload').length === 2) {
                ACC.enquiryb2b.validator.element('.file-upload');
            }
        });
    },

    /**
    * When user updates their chosen Enquiry Type, display the appropriate Enquiry level 2/3 additional fields.
    */
    onEnquiryTypeChange: function onEnquiryTypeChange() {
        var enquiryType1 = document.getElementById('enquiryType1');
        var selected = $('option:selected', enquiryType1).val();
        /**
        * Show the additional fields if the Enquiry Type dropdown is already selected
        * Happens when there is a backend error when posting the form.
        */
        if (selected !== null && selected !== '') {
            ACC.enquiryb2b.renderEnquiry2Types(selected);
        }

        /**
        * Event handler for Enquiry Type dropdown
        */
        $(document).on('change', '#enquiryType1', function () {
            selected = $(this).val();
            ACC.enquiryb2b.removeValidationState();
            //Hide error messages if shown
            $('#send-enquiry__form-errorMessage').hide();
            $('.enquiry__order__msg').addClass('hidden');
            var stockMessage = $('.enquiry__order__stock_msg');
            if (stockMessage) {
                stockMessage.addClass('hidden');
            }
            var btnContainer = $('.btn--add-product');
            if (btnContainer) {
                btnContainer.removeClass('hidden');
            }

            $('#enquiryType1').val(selected);
            // Reset the amount of product fields
            ACC.enquiry.productIndex = 0;
            $('.product.additional').remove();
            // Update if Message and upload attachment fields are required
            if (selected === 'ORDER_RETURN') {
                enquiryOrder.init(selected);
            }
            if (selected !== null) {
                ACC.enquiryb2b.renderEnquiry2Types(selected);
            }
        });

        /**
        * Event handler for Enquiry level 2 fields that can trigger
        * further Enquiry level 3 fields
        */
        $(document).on('change', '[data-enquiry-additional-fields]', function () {
            if (selected === 'DAMAGED_STOCK') {
                var enquiry2Selected = 'DAMAGED_STOCK';
            } else if (selected === 'MISSING_STOCK') {
                var enquiry2Selected = 'MISSING_STOCK';
                var enquiry2val = $(this).val();
            } else {
                var enquiry2Selected = $(this).val();
            }

            if (enquiry2Selected !== null && !enquiry2Selected.toString().includes("ZF") && !enquiry2Selected.toString().includes('ZL')) {
                ACC.enquiryb2b.renderEnquiry3Types(enquiry2Selected);
                if (selected === 'DAMAGED_STOCK' || selected === 'MISSING_STOCK') {
                    enquiryOrder.init(selected);
                }

                if (enquiry2val !== null && enquiry2val === 'Z39') {
                    var btnContainer = $('.btn--add-product');
                    btnContainer.addClass('hidden');
                    $('.enquiry-level--4').addClass('hidden');
                }
                if (enquiry2val !== null && enquiry2val === 'Z41') {
                    var _btnContainer = $('.btn--add-product');
                    _btnContainer.removeClass('hidden');
                    $('.enquiry-level--4').addClass('hidden');
                }
            }
        });
    },

    /**
     * Logic to display the selected Enquiry level 2 fields
     */
    renderEnquiry2Types: function /*Babel ES6 transpiled!*/renderEnquiry2Types(enquiryTypeCode) {

        /* Hide all fieldsets */
        $('.enquiry-level--2').addClass('hidden');
        $('.enquiry-level--3').addClass('hidden');
        $('.enquiry-level--4').addClass('hidden');

        /* Show the selected fieldset */
        var selectedFieldset = $( /*Babel ES6 transpiled!*/".enquiry-level--2[data-enquiry-code=\"" + enquiryTypeCode + "\"]");
        selectedFieldset.removeClass('hidden');

        /* Enable the fields in the selected fieldset. Disable and hide all other fields. */
        var fieldsets = Array.from($('.enquiry-level--2'));
        fieldsets.forEach(function (fieldset, index) {
            var fields = Array.from($(fieldset).find('select, input, textarea'));
            // If it's the selected fieldset, un-disable the fields
            if (fieldset === selectedFieldset[0]) {
                fields.forEach(function (field) {
                    field.disabled = false;
                    // If a field has a default value and additional fields 
                    // associated with it, render the additional fields.
                    if (field.dataset.default !== undefined && field.dataset.enquiryAdditionalFields) {
                        ACC.enquiryb2b.renderEnquiry3Types(field.dataset.default);
                    }
                });
                return;
                // Else, disable and reset all other field's values
            } else {
                fields.forEach(function (field) /*Babel ES6 transpiled!*/{
                    return ACC.enquiryb2b.resetField(field);
                });
            }
        });

        /**
         * Show the file upload container for ENQUIRY_TYPE_KEY_1 or DAMAGED_STOCK option.
         * Hide the file upload container for all other options.
         */

        // hide file-upload-container
        if ($(ACC.enquiryb2b.fileUploadInput).length) {
            var removeFiles = Array.from($('.js-remove-file'));
            removeFiles.forEach(function (file) {
                $(file).remove();
            });

            var fileList = Array.from($('.file-list--item'));
            fileList.forEach(function (file) {
                $(file).remove();
            });

            ACC.enquiryb2b.fileUpload.removeValidationState();
        }
        $(ACC.enquiryb2b.fileUploadContainer).addClass('hidden');

        // show file-upload-container and initialise
        if (enquiryTypeCode === 'QUALITY_ISSUE_PRODUCT' || enquiryTypeCode === 'DAMAGED_STOCK') {

            if (enquiryTypeCode === 'DAMAGED_STOCK') {
                ACC.enquiryb2b.fileUpload.updateProperties(true);
            } else {
                ACC.enquiryb2b.fileUpload.updateProperties(false);
            }
            ACC.enquiryb2b.fileUpload.resetFileUpload();

            $(ACC.enquiryb2b.fileUploadContainer).removeClass('hidden');
        }
        //update required field validation
        ACC.enquiryb2b.updateRequiredField(enquiryTypeCode);
    },

    /**
     * Logic to display the selected Enquiry level 3 fields
     */
    renderEnquiry3Types: function /*Babel ES6 transpiled!*/renderEnquiry3Types(enquiryTypeCode) {
        //Hide error messages if shown
        $('#send-enquiry__form-errorMessage').hide();
        $('.enquiry__order__msg').addClass('hidden');
        var stockMessage = $('.enquiry__order__stock_msg');
        if (stockMessage) {
            stockMessage.addClass('hidden');
        }
        /* Get the selected fieldset and their fields */
        var selectedFieldset = $( /*Babel ES6 transpiled!*/".enquiry-level--3[data-enquiry-code=\"" + enquiryTypeCode + "\"]");
        var fields;
        if (selectedFieldset.length) {
            fields = selectedFieldset.find('select, input, textarea');
        }

        /* Hide and disable all other fields */
        // Hide all other fields
        $('.enquiry-level--3').addClass('hidden');
        // Reset product count
        ACC.enquiry.productIndex = 0;
        $('.product.additional').remove();
        // Disable all other fields
        var fieldsets = Array.from($('.enquiry-level--3'));
        fieldsets.forEach(function (fieldset) {
            var fieldsetFields = Array.from($(fieldset).find('select, input, textarea'));
            fieldsetFields.forEach(function (field) {
                ACC.enquiryb2b.resetField(field);
            });
        });

        /* Show and enable the selected field */
        selectedFieldset.removeClass('hidden');
        if (fields) {
            fields = Array.from(fields);
            fields.forEach(function (field) /*Babel ES6 transpiled!*/{
                return field.disabled = false;
            });
        }
    },

    /**
     * Add another Product field to the Product list
     */
    addProduct: function /*Babel ES6 transpiled!*/addProduct() {
        $('.send-enquiry--b2b').on('click', '.btn--add-product', function () {
            // Increment the product count
            ACC.enquiry.productIndex++;
            ACC.enquiryb2b.addProductValidatorGroup(ACC.enquiry.productIndex);

            // Get: Product Group, Product list container, and the Enquiry Type code.
            var productGroup = $(this).closest('[data-enquiry-code]');
            var productsContainer = productGroup.find('.products');
            var enquiryTypeCode = productGroup.attr('data-enquiry-code');

            // Generate the product template
            var productTemplate = ACC.enquiryb2b.productTemplate(ACC.enquiry.productIndex);
            // Append it to the Product list container
            productsContainer[0].insertAdjacentHTML('beforeend', productTemplate);
        });
    },

    addProductValidatorGroup: function /*Babel ES6 transpiled!*/addProductValidatorGroup(productIndex) {

        ACC.enquiryb2b.validator.groups[/*Babel ES6 transpiled!*/"product" + productIndex] = /*Babel ES6 transpiled!*/"enquiryProducts[" + productIndex + "].product enquiryProducts[" + productIndex + "].productQuantity";
    },

    getProductIndex: function /*Babel ES6 transpiled!*/getProductIndex() {
        // console.log(ACC.enquiry.productIndex);
        return ACC.enquiry.productIndex;
    },

    /**
     * Remove the selected Product field from the Product list
     */
    removeProduct: function /*Babel ES6 transpiled!*/removeProduct() {
        $('.send-enquiry--b2b').on('click', '.btn--remove', function () {
            if ($(this).closest('.products').find('.product').length > 1) {
                $(this).closest('.product').remove();
            }
        });
    },

    /**
     * Reset a field
     */
    resetField: function /*Babel ES6 transpiled!*/resetField(field) {
        field.disabled = true;
        // Reset inputs by resetting the value.
        // If the field has a default value, set the value as the [data] attribute from that field.
        field.value = field.dataset.default || "";

        // If the field is a dropdown, toggle the colour if it has/doesn't have a value.
        if (field.type === 'select-one') {
            ACC.enquiryb2b.toggleSelectColour(field);
        }

        // Remove all the Validator poop
        $(field).removeClass('input-msg input-error is-valid has-error');
        ACC.enquiryb2b.validator.hideThese(ACC.enquiryb2b.validator.errorsFor(field));
        field.removeAttribute('aria-invalid');
        var fieldContainer = $(field).closest('.form-group');
        if (fieldContainer) {
            fieldContainer.removeClass('is-valid has-error');
            if (!field.value) {
                fieldContainer.removeClass('is-active');
            }
        }
    },

    /**
     * Templates for different product groups when "Add Product" is clicked.
     * For example, Keg products have different fields from the Default.
     */
    productTemplate: function /*Babel ES6 transpiled!*/productTemplate() {
        /*Babel ES6 transpiled!*/var productIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

        var dropdown = /*Babel ES6 transpiled!*/"\n        <div class=\"form-group\">\n            <select class=\"enquiry__order-product js-enquiry-order-product form-control required untouched\" id=\"productList" + productIndex + "\" name=\"enquiryProducts[" + productIndex + "].product\" data-product-index=\"" + productIndex + "\" data-msg-required=\"" + ACC.sendEnquiry.productErrorText + "\" required>\n                " + enquiryOrder.orderProductDropdownOptions() + "\n            </select>\n            <input type=\"hidden\" id=\"productName\" name=\"enquiryProducts[" + productIndex + "].productName\" path=\"enquiryProducts[" + productIndex + "].productName\" data-product-index=\"" + productIndex + "\" />\n            <input type=\"hidden\" id=\"productEAN\" name=\"enquiryProducts[" + productIndex + "].productEAN\" path=\"enquiryProducts[" + productIndex + "].productEAN\" data-product-index=\"" + productIndex + "\" />\n            <input type=\"hidden\" id=\"productLineNumber\" name=\"enquiryProducts[" + productIndex + "].productLineNumber\" path=\"enquiryProducts[" + productIndex + "].productLineNumber\" data-product-index=\"" + productIndex + "\" />\n            <input type=\"hidden\" id=\"productUom\" name=\"enquiryProducts[" + productIndex + "].productUom\" path=\"enquiryProducts[" + productIndex + "].productUom\" data-product-index=\"" + productIndex + "\" />\n        </div>";

        /* Keg product type template */
        // (Removed - Keg products are no longer able to add additional products.)

        /* Default product template */
        var productTemplate = /*Babel ES6 transpiled!*/"<div class=\"product additional\">\n            <div class=\"row form-group-container\">\n                <div class=\"col-xs-12 col-sm-8\">\n                    <div class=\"col-xs-8 col-sm-10 no-left-gutter\">\n                        " + dropdown + "\n                    </div>\n                    <div class=\"col-xs-3 col-sm-2 no-right-gutter\">\n                        <div class=\"form-group\">\n                            <label for=\"productQuantity" + productIndex + "\">" + ACC.sendEnquiry.qty + "</label>\n                            <input class=\"form-control enquiry__order-product-quantity\" data-element=\"qty-max\" type=\"number\" min=\"1\" step=\"1\" name=\"enquiryProducts[" + productIndex + "].productQuantity\" path=\"enquiryProducts[" + productIndex + "].productQuantity\" id=\"productQuantity" + productIndex + "\" data-msg-required=\"" + ACC.sendEnquiry.productQuantityErrorText + "\" data-product-index=\"" + productIndex + "\" required>\n                        </div>\n                    </div>\n                    <a class=\"btn--remove btn--no-style\">\n                        <svg class=\"icon icon--circle-remove\">\n                            <use xlink:href=\"#icon-circle-remove\"></use>\n                        </svg>\n                    </a>\n                </div>\n                <div class=\"input-msg-group col-xs-12 col-sm-4\"></div>\n            </div>\n        </div>";
        return productTemplate;
    },

    wholeDeliveryProductTemplate: function /*Babel ES6 transpiled!*/wholeDeliveryProductTemplate() {
        /*Babel ES6 transpiled!*/var productIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

        var wholeDeliveryProductTemplate = /*Babel ES6 transpiled!*/"<div class=\"whole-product-list\"><div id=\"Acc-title\" class=\"accordion\"></div>\n     <div class=\"panels\">\n     <table  style=\"padding-bottom: 10px;\">\n     " + enquiryOrder.orderProductList() + "\n     <table>\n     </div></div>";
        return wholeDeliveryProductTemplate;
    },

    /**
     * Initialise the Date/Time picker for Keg Best Before fields
     */
    initDateTimePicker: function /*Babel ES6 transpiled!*/initDateTimePicker() {
        /*Babel ES6 transpiled!*/var productIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

        $('#kegBestBefore-' + productIndex).datetimepicker({
            format: 'YYYY/MM/DD HH:mm'
        });
    },

    /**
     * Update if the Message field is required, depending on Enquiry Type selected.
     */
    updateRequiredField: function /*Babel ES6 transpiled!*/updateRequiredField(enquiryTypeCode) {
        var message = document.getElementById('message');
        var uploadAttachment = document.getElementsByClassName('file-upload');

        switch (enquiryTypeCode) {
            case 'QUALITY_ISSUE_PRODUCT':
                message.required = true;
                uploadAttachment[0].required = false;
                break;
            case 'FEEDBACK_ON':
                message.required = true;
                uploadAttachment[0].required = false;
                break;
            case 'NEW_FRIDGE':
                message.required = true;
                uploadAttachment[0].required = false;
                break;
            case 'FRIDGE_MAINTENANCE':
                message.required = true;
                uploadAttachment[0].required = false;
                break;
            case 'EMPTY_KEGS':
                message.required = false;
                uploadAttachment[0].required = false;
                break;
            case 'ORDER_RETURN':
                message.required = false;
                uploadAttachment[0].required = false;
                break;
            case 'PRODUCT_RECALL':
                message.required = false;
                uploadAttachment[0].required = false;
                break;
            case 'DAMAGED_STOCK':
                message.required = false;
                uploadAttachment[0].required = true;
                break;
            default:
                message.required = true;
        }
    },

    /**
     * Handler for updating dropdown text colour
     */
    toggleSelectHandler: function /*Babel ES6 transpiled!*/toggleSelectHandler(select) {
        ACC.enquiryb2b.toggleSelectColour(select);
    },

    /**
     * Update dropdown text colour if it has a value or not
     */
    toggleSelectColour: function /*Babel ES6 transpiled!*/toggleSelectColour(select) {
        if (select.value === '') {
            select.classList.add('untouched');
        } else {
            select.classList.remove('untouched');
        }
    },

    /**
     * Submit the form
     */
    submitForm: function /*Babel ES6 transpiled!*/submitForm() {
        var form = document.querySelector(ACC.enquiry.form);
        // Submit form
        form.submit();
    },
    /**
     * Remove Enquiry Types for AU B2B
     */
    removeAuEnquiryType: function /*Babel ES6 transpiled!*/removeAuEnquiryType() {
        if (ACC.config.isNzSite === "false") {
            $('.nzEnquiryType').remove();
        }
    },
    /**
     * Remove Enquiry Types for NZ B2B
     */
    removeNzEnquiryType: function /*Babel ES6 transpiled!*/removeNzEnquiryType() {
        if (ACC.config.isNzSite === "true") {
            $('.auEnquiryType').remove();
        }
    },

    fileUploadInit: function /*Babel ES6 transpiled!*/fileUploadInit() {
        ACC.enquiryb2b.fileUpload = new FileUpload(ACC.enquiryb2b.form, ACC.enquiryb2b.fileSizeLimit, ACC.enquiryb2b.fileQtyLimit, ACC.enquiryb2b.validFileExtensions);
        ACC.enquiryb2b.fileUpload.init();
    },

    /*Babel ES6 transpiled!*/removeValidationState: function removeValidationState() {
        var form = $('#enquiryForm');
        var validator = form.validate();

        //reset the form
        validator.resetForm();
        $(form).find('input, select, textarea').each(function () {
            if ($(this).parent('.form-group').length > 0) {
                $(this).parent('.form-group').removeClass('is-valid').removeClass('has-error');
            } else {
                $(this).removeClass('is-valid').removeClass('has-error').removeClass('is-valid');
            }
        });
    }
};
