ACC.enquiry = {
    productIndex:0,
    _autoload: [
        ["validateEnquiryForm", $('.send-enquiry--b2c').length != 0],
    ],
    form: '#enquiryForm',
    
    validateEnquiryForm: function() {
        $(ACC.enquiry.form).validate({
            onfocusout: function (element) { // Eager validation
                this.element(element);
            },
            messages: {
                name: $('#enquiryForm label[for="name"]').data('error'),
                emailAddress: {
                    required: $('#enquiryForm label[for="emailAddress"]').data('errorRequired'),
                    email: $('#enquiryForm label[for="emailAddress"]').data('errorEmail')
                },
                message: $('#enquiryForm label[for="message"]').data('error')
            },
            rules: {
            },
            ignore: '.ignore-validation',
            highlight: function(element) {
                $(element).closest('.form-group').addClass('has-error').removeClass('is-valid');
                $(element).addClass('has-error').removeClass('is-valid');
            },
            unhighlight: function(element) {
                $(element).closest('.form-group').removeClass('has-error').addClass('is-valid');
                $(element).removeClass('has-error').addClass('is-valid');
            },
            errorElement: 'div',
            errorClass: 'input-msg input-error',
            errorPlacement: function(error, element) {
                var row = $(element).closest('.row');
                var msgGroup = $(row).find('.input-msg-group');
                $(msgGroup).append(error);
            },
            errorContainer: '#send-enquiry__form-error'
        });
    },

};