ACC.global = {

    _autoload: [
        ["passwordStrength", $('.password-strength').length > 0],
        "heroBannerCarousel",
        "bindToggleOffcanvas",
        "bindToggleXsSearch",
        "bindHoverIntentMainNavigation",
        "initImager",
        "backToHome","bindLoginChecks",
        "showHidePwd",
        "formInputFloatingLabel",
        "checkInputPassword",
        "checkAlert",
        "closeAlert",
        "resetAlert",
        "confirmPasswordReset",
        "layoutPage2Column",
        ["hideHeaderNavigation", $('.customer-registration__ff').length > 0],
        "headerNavigation",
        "stickyNav",
        "productListing",
        "clearSearch",
        "fixFooter",
        "continueShopping",
        "formatCheckoutTotal",
        "onMobileMenuOpen",
        "showMobileMenu",
        "dynamicLink"
    ],



    bindLoginChecks: function () {
        $('#j_username').blur(function () {
            var username = document.getElementById("j_username");
            var errorMessage =  document.getElementById("username_error");
            if(username.value == ''){
                errorMessage.className="help-block";
                username.parentElement.classList.add('has-error');
                errorMessage.innerHTML="Please enter your email address";
            } else {
                if(ACC.global.checkEmail()) {
                    errorMessage.className="hidden";
                    username.parentElement.classList.remove('has-error');
                    errorMessage.innerHTML="";
                } else {
                    errorMessage.className = 'help-block';
                    username.parentElement.classList.add('has-error');
                    errorMessage.innerHTML="Please enter valid email address";
                }
            }
        });
    },

    passwordStrength: function () {
        $('.password-strength').pstrength({
            verdicts: [ACC.pwdStrengthTooShortPwd,
                ACC.pwdStrengthVeryWeak,
                ACC.pwdStrengthWeak,
                ACC.pwdStrengthMedium,
                ACC.pwdStrengthStrong,
                ACC.pwdStrengthVeryStrong],
            minCharText: ACC.pwdStrengthMinCharText
        });
    },

    bindToggleOffcanvas: function () {
        var that = this;
        $(".js-toggle-sm-navigation").click(function () {
            that.toggleHamburgerOpenIcon($(".mobile-nav__container").hasClass("mobile-nav__container--open"));
            $(".mobile-nav__container").toggleClass("mobile-nav__container--open");
            $(".mobile-nav__container").removeClass("mobile-nav__container--search");
        });
    },

    bindToggleXsSearch: function () {
        var that = this;
        $('.js-toggle-xs-search').click(function () {
            $(".mobile-nav__container").toggleClass("mobile-nav__container--search");
            $(".mobile-nav__container").removeClass("mobile-nav__container--open");
            that.toggleHamburgerOpenIcon(!$(".mobile-nav__container").hasClass("mobile-nav__container--open"));
        });
    },

    toggleHamburgerOpenIcon: function(shouldOpen, caller) {
        $('.js-header-nav-mobile').toggleClass('icon-header-nav-mobile', shouldOpen)
        .toggleClass('icon-header-nav-mobile-close', !shouldOpen);
    },

    onMobileMenuOpen: function() {
                $('.js-header-nav-mobile').click(function() {
                        if (!$(this).hasClass('icon-header-nav-mobile-close')) {
                                ACC.changevenuepopup.getVenueInformation();
                    }
                   });
            },
    showMobileMenu: function(){
        if($('.nav__link-mobile').length != 0) {
            $('.mobile-header-hamburger').removeClass('hidden')
        }
    },

    resetXsSearch: function () {
        $('.site-search').removeClass('active');
        $(".js-mainHeader .navigation--middle").removeClass("search-open");
    },

    toggleClassState: function ($e, c) {
        $e.hasClass(c) ? $e.removeClass(c) : $e.addClass(c);
        return $e.hasClass(c);
    },

    bindHoverIntentMainNavigation: function () {

        enquire.register("screen and (min-width:" + screenMdMin + ")", {

            match: function () {
                var showSubMenu = function(){
                    var $this = $(this),
                    itemWidth = $this.width();
                    var $subNav = $this.find('.sub-navigation__container'),
                        subNavWidth = $subNav.outerWidth();
                    var $mainNav = $('.js_navigation--bottom'),
                        mainNavWidth = $mainNav.width();

                    // get the left position for sub-navigation to be centered under each <li>
                    var leftPos = $this.position().left + itemWidth / 2 - subNavWidth / 2;
                    // get the top position for sub-navigation. this is usually the height of the <li> unless there is more than one row of <li>
                    var topPos = $this.position().top + $this.height() + 7;

                    if (leftPos > 0 && leftPos + subNavWidth < mainNavWidth) {
                        // .sub-navigation is within bounds of the .main-navigation
                        $subNav.css({
                            "left": leftPos,
                            "top": topPos,
                            "right": "auto"
                        });
                    } else if (leftPos < 0) {
                        // .suv-navigation can't be centered under the <li> because it would exceed the .main-navigation on the left side
                        $subNav.css({
                            "left": "auto",
                            "top": topPos,
                            "right": "auto"
                        });
                    } else if (leftPos + subNavWidth > mainNavWidth) {
                        // .suv-navigation can't be centered under the <li> because it would exceed the .main-navigation on the right side
                        $subNav.css({
                            "right": "auto",
                            "top": topPos,
                            "left": "auto"
                        });
                    }
                    $subNav.addClass('sub-navigation__container--open');
                };

                var hideSubMenu = function(){
                    $(this).find('.sub-navigation__container').removeClass('sub-navigation__container--open');
                };

                var config = {    
                    sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)    
                    interval: 100, // number = milliseconds for onMouseOver polling interval    
                    over: showSubMenu, // function = onMouseOver callback (REQUIRED)    
                    timeout: 300, // number = milliseconds delay before onMouseOut    
                    out: hideSubMenu // function = onMouseOut callback (REQUIRED)
                };
                // on screens larger or equal screenMdMin (1024px) calculate position for .sub-navigation
                $(".nav__links--primary").hoverIntent(config);
            },

            unmatch: function () {
                // on screens smaller than screenMdMin (1024px) remove inline styles from .sub-navigation and remove hoverIntent
                $(".js_sub__navigation").removeAttr("style");
                $(".js-enquire-has-sub").hoverIntent(function () {
                    // unbinding hover
                });

                $('.nav__links--primary').hoverIntent(function() {
                    // unbind
                });
            }

        });

        enquire.register("screen and (max-width:" + screenSmMin + ")", {
            match: function () {
                $(".nav__links--primary").click(function(){
                    var $this = $(this);
                    var $subNav = $this.find('.sub-navigation__container');

                    $subNav.toggleClass('sub-navigation__container--open');
                });
            },

            unmatch: function() {
                $(".nav__links--primary").click(function(){
                    // remove click event
                });
            }
        });
    },

    initImager: function (elems) {
        elems = elems || '.js-responsive-image';
        this.imgr = new Imager(elems);
    },

    reprocessImages: function (elems) {
        elems = elems || '.js-responsive-image';
        if (this.imgr == undefined) {
            this.initImager(elems);
        } else {
            this.imgr.checkImagesNeedReplacing($(elems));
        }
    },

    // usage: ACC.global.addGoogleMapsApi("callback function"); // callback function name like "ACC.global.myfunction"
    addGoogleMapsApi: function (callback) {
        if (callback != undefined && $(".js-googleMapsApi").length == 0) {
            $('head').append('<script class="js-googleMapsApi" type="text/javascript" src="//maps.googleapis.com/maps/api/js?key=' + ACC.config.googleApiKey + '&sensor=false&callback=' + callback + '"></script>');
        } else if (callback != undefined) {
            eval(callback + "()");
        }
    },

    backToHome: function () {
        $(".backToHome").on("click", function () {
            var sUrl = ACC.config.contextPath;
            window.location = sUrl;
        });
    },

    showHidePwd: function() {
        var timeout;
        var inactivityTimeForPassword = 1000*60;

        var resetTimer = function() {
            clearTimeout(timeout);
            timeout = setTimeout(removeVisiblePasswords, inactivityTimeForPassword);
        };

        $(".toggle-password").click(function() {
            resetTimer();
            toggleHideShow(this, true);
        });

        var toggleHideShow = function(control, toggle) {
            var $togglePassword = $(control);
            if(!toggle && !$togglePassword.hasClass('glyphicon-eye-close')) return;
            if(!$togglePassword.hasClass('glyphicon-eye-close')) {
                removeVisiblePasswords();
            }
            var $revealText = $togglePassword.siblings('.reveal-text');
            $togglePassword.toggleClass("glyphicon-eye-close");
            
            $revealText.text(($revealText.text() === ACC.showPassword) ? ACC.hidePassword : ACC.showPassword);
            var $input = $($togglePassword.attr("toggle"));
            if ($input.attr("type") === "password") {
                $input.attr("type", "text");
            } else {
                $input.attr("type", "password");
            }
        };

        var checkForClicksOutsidePassword = function(e) {
            if($(e.target).hasClass('toggle-password')) return;
            removeVisiblePasswords();
        }

        var removeVisiblePasswords = function() {
            var togglePasswordControls = $('.toggle-password');
            if(togglePasswordControls) {
                for(var i=0; i < togglePasswordControls.length; i++) {
                    toggleHideShow(togglePasswordControls[i], false);
                }
            }
        }

        if($(".toggle-password").length) {
            $(window).click(checkForClicksOutsidePassword);
        }

        $(window).on('unload', function() {
            $(window).unbind('click', checkForClicksOutsidePassword);
        });
    },

    formInputFloatingLabel: function() {
        var inputs = 'label + input.form-control, label + textarea.form-control, .form-group input.form-control, .form-group textarea.form-control';

        $(document).on('focus', inputs, function (event) {
            if ($(this).attr('readonly') !='readonly'){
                $(this).parent().addClass('is-active');
            }
        });
        $(document).bind('change', inputs, function(e){
            if ($(e.target).val() === '') {
                $(e.target).parent().removeClass('is-active');
            } else {
                $(e.target).parent().addClass('is-active');
            }
        });
        $(document).on('blur', inputs, function (event) {
            if ($(this).val() === '') {
                $(this).parent().removeClass('is-active');
            }
        });
        for (var i = 0; i < $(inputs).length; i++) {
            if ($(inputs)[i].value !== '') {
                $(inputs)[i].parentElement.classList.add('is-active');
            }
        }

    },

    checkInputPassword: function() {
        $('#loginForm').ready(function() {
            if($('#j_username').val() !== '') {
                $("#j_password").parent().addClass("is-active");
            }
        });
    },

    checkEmail: function() {
        var email = $("#j_username").val();
        var test = email.match(/^[_A-Za-z0-9-\+]+([\.\'][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);
        if (typeof test === "undefined" || test === null) {
            return false;
        } else {
            return true;
        }
    },

    checkEmailGuest: function () {
        var emailGuest = $("#guest_email").val();
        var testGuest = emailGuest.match(/^[_A-Za-z0-9-\+]+([\.\'][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);
        if (typeof testGuest === "undefined" || testGuest === null) {
            return false;
        } else {
            return true;
        }
    },

    checkForm: function() {
        var errorMessage =  document.getElementById("username_error");
        if($('#j_username').val() == ''){
            errorMessage.className="";
            errorMessage.innerHTML="Please enter your email address";
        } else {
             if(ACC.global.checkEmail()) {
                 errorMessage.className="hidden";
                 errorMessage.innerHTML="";
             } else {
                 errorMessage.className="help-block";
                 errorMessage.innerHTML="Please enter valid email address";
             }
        }
        var errorMessage =  document.getElementById("password_error");
        if($('#j_password').val() == ''){
            errorMessage.className="help-block";
            errorMessage.innerHTML="Please enter your password";
        } else {
            errorMessage.className="hidden";
            errorMessage.innerHTML="";
        }
        if($('#j_username').val() == '' || $('#j_password').val() == '' ){
            return false;
        } else {
            return true;
        }
    },

    checkAlert: function() {
         if($.cookie('alert_closed') == 0 || $.cookie('alert_closed') == null){
             $('.alertMessage').css('display','block');
         }
    },

     closeAlert: function() {
         $('.js-closeAlert').on('click', function() {
             $.cookie('alert_closed',1, { path : '/' });
          $('.alertMessage').css('display','none');

         });
     },

      resetAlert: function() {
        //Display alert if user logs out
        $('.js-logout').on('click',function() {
            //Remove AgeConfirmed session
            localStorage.removeItem('AgeConfirmed');
            $.cookie('alert_closed', 0, { path: '/' });
        });

        //Display alert if user starts a new session
        if (!sessionStorage.isNewSession){
           $('.alertMessage').css('display','block');
        }
        sessionStorage.isNewSession = true;
      },

    confirmPasswordReset: function() {

        $('#updatePwdForm').ready(function() {
           $("#password_minchar").html('');
       });

        var passRule = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,128}$/;

        $('#updatePwdForm').on('submit', function(e) {

             $("#checkPwdErr").remove();
             $("#password_minchar").html('');

             var errorMsg1 = "- one uppercase character<br />- one lowercase character<br />- one number<br />- 10 characters minimum";
             var errorMsg2 = "<br />- Password Mismatch";

             var myPwd = $("#password").val();
             var myPwdChk = $("input[name=checkPwd]").val(); //fetch input value by name attribute, conflict with id value "updatePwd.checkPwd"

             if(!myPwd.match(passRule) || (myPwdChk !== myPwd)){ //
                if(!myPwd.match(passRule))
                    $("#password_minchar").html(errorMsg1);
                else
                    $("#password_minchar").html('');
                if(myPwdChk !== myPwd)
                    $("#form-group-checkPwd").append("<div id=\"checkPwdErr\" class=\"help-block\">"+errorMsg2+"<div>");
                else
                    $("#checkPwdErr").remove();

                $("#password").val("");
                $("input[name=checkPwd]").val("");
                e.preventDefault();
                return;
             }
        });
    },

    layoutPage2Column: function() {

        var emailRule = /^[_A-Za-z0-9-\+]+([\.\'][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
        
        $('#page--two-column').ready(function() {

            $(".page__side-content").height($(".page__body-container").height());

            $(".account-section-content").find("span").each(function(){
                $(this).click(function() {
                    if($(this).hasClass('glyphicon-chevron-down') && $(this).hasClass('vertical-nav-toggle'))
                        $(this).removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
                    else if($(this).hasClass('glyphicon-chevron-up') && $(this).hasClass('vertical-nav-toggle'))
                        $(this).removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
                });
            });

            $(".faq-question-div").find("svg").each(function(){
                $(this).click(function() {
                    if($(this).hasClass('icon--arrow-right')){
                        $(this).removeClass('icon--arrow-right').addClass('icon--arrow-down');
                        $("." + $(this).attr('id') + " .inactive-FAQ").removeClass('inactive-FAQ').addClass('active-FAQ');                        
                    }
                    else{
                        $(this).removeClass('icon--arrow-down').addClass('icon--arrow-right');
                        $("." + $(this).attr('id') + " .active-FAQ").removeClass('active-FAQ').addClass('inactive-FAQ');
                    }
                });
            });
        });

        $('.side-nav__container').ready(function() {
            $('.vertical-nav-toggle').click(function() {
                if($('.side-nav__container').hasClass('side-nav__container--collapsed')){
                    $('.side-nav__container').removeClass('side-nav__container--collapsed');
                    $('.vertical-nav-toggle').find('svg').removeClass('icon--arrow-down').addClass('icon--arrow-up');
                }else{
                    $('.side-nav__container').addClass('side-nav__container--collapsed');
                    $('.vertical-nav-toggle').find('svg').removeClass('icon--arrow-up').addClass('icon--arrow-down'); 
                }
            });
        });

        $('#lionUpdateProfileForm').ready(function() {
            if ($('.global-alerts').length){
                $(".global-alerts").find("div").each(function(){
                    if($(this).hasClass("alert-info")){
                        $(".js-success-msg").show();
                        setTimeout(function() {
                           $(".js-success-msg").hide();
                        }, 5000);
                    }
                });
            }
        });

        $('#lionUpdatePasswordForm').ready(function() {
            if($(this).find('.help-block').length !== 0){
                $(".help-block").find("span").each(function(){
                    if($(this).attr('Id') == "currentPassword.errors"){
                        $('.js-curr-password-error').addClass('input-error--show');
                    }
                });
            }

            if ($('.global-alerts').length){
                $(".global-alerts").find("div").each(function(){
                    if($(this).hasClass("alert-info")){
                        $(".js-success-msg").show();
                        setTimeout(function() {
                           $(".js-success-msg").hide();
                        }, 5000);
                    }
                });
            }
        });
    },

    hideHeaderNavigation: function () {
        $('.js-navigation--middle').remove();
    },

    headerNavigation: function() {
        $(".navigation--top").find(".mobile-header-item").each(function(){
            if($(this).find('div.js-toggle-sm-navigation').length !== 0){
                $(this).find('div.js-toggle-sm-navigation').click(function(){
                    $(".site-search").find(".searchbox-pageslot-component").each(function(){
                        if($(this).find('nav.navigation').length !== 0){
                            $(this).find('nav.navigation').parent().removeClass('searchbox-pageslot-component col-md-7');
                        }
                    });
                });
            }

            if($(this).find('div.js-shop-list-link-mobile').length !== 0 ){
                if($(".navigation--bottom__row").find(".searchbox-pageslot-component > a").length !== 0){
                    var ShopListHref = $(".navigation--bottom__row").find(".searchbox-pageslot-component > a").attr("href");
                    $(this).find('div.js-shop-list-link-mobile > div').wrap( '<a href="' + ShopListHref +'"></a>' );
                }else{
                    $(this).find('div.js-shop-list-link-mobile').remove();
                }
            }
        });

        $('.shopping-list-link').ready(function() {
            var mobileLinksHolder = $('.js-header-links__placeholder');
            $(mobileLinksHolder).append($('.shopping-list-link').clone(true));
        });

        $('.user-greeting__message').ready(function(){
            var welcomeHolder = $('.js-header-links__placeholder');
            welcomeHolder.append($('.user-greeting__message').clone(true));
        });

        $('.user-balance-points').ready(function(){
            var pointsBalance = $('.js-header-links__placeholder');
            pointsBalance.append($('.user-balance-points').clone(true));
        });

        // Change Venue dropdown menu
        $('.change-venue__container').ready(function() {
            var mobileLinksHolder = $('.js-header-links__placeholder');
            $(mobileLinksHolder).append($('.welcome-message').clone(true));
            $(mobileLinksHolder).find(".welcome-message:not(:eq(0))").remove();
            $(mobileLinksHolder).find("div.js-user-dropdown__container").removeClass('dropdown-menu');
        });

        $('.navigation-link__container').ready(function() {
            var mobileLinksHolder = $('.js-header-links__placeholder');
            $('.navigation-link__container').clone(true).appendTo(mobileLinksHolder);
        });

        $('.navigation-link__container').click(function() {
            var dropdown = $(this).find('.navigation-link__dropdown');
            $(dropdown).toggleClass('navigation-link__dropdown--open');
        });

        // hide the menu options on document click
        $(document).click(function(event){
            if(event.target.offsetParent) {
                $(this).find('.navigation--top .js-logged_in input[type="radio"]').not($(event.target.offsetParent)).prop('checked', false);
                $(this).find('.navigation--top .navigation-link__dropdown').not($(event.target.offsetParent)).removeClass('navigation-link__dropdown--open');
            } 
        })

        $('.logout-link__container').ready(function() {
            var mobileLinksHolder = $('.js-header-links__placeholder');
            $(mobileLinksHolder).append($('.logout-link__container').clone(true));
        })

        $('.header-phoneNo-container').ready(function(){
            var phoneNumberDiv = $('.js-phone-number__container');
            phoneNumberDiv.append($('.header-phoneNo-container').html());
        });

        $('.login-link').ready(function() {
            var mobileLinksHolder = $('.js-header-links__placeholder');
            $(mobileLinksHolder).append($('.login-link').clone(true));
        });

        $('.js-site-search-input').ready(function() {
            $('.js-site-search-input').keyup(function(){
                if($(this).val().length !== 0)
                    $('.js-search-box-close--icon').removeClass('search-box-close_icon--hidden');
                else
                    $('.js-search-box-close--icon').addClass('search-box-close_icon--hidden');
            });

            if($(this).val().length !== 0)
                $('.js-search-box-close--icon').removeClass('search-box-close_icon--hidden');
        });

        $('.js-page-not-found-search').ready(function() {
            $(document).on('keyup', '.js-page-not-found-search', function() {
                if($(this).val().length)
                    $('.js-page-not-found-close--icon').removeClass('search-box-close_icon--hidden');
                else
                    $('.js-page-not-found-close--icon').addClass('search-box-close_icon--hidden');
            });

            if($(this).val().length)
                $('.js-page-not-found-close--icon').removeClass('search-box-close_icon--hidden');
        });
    },

    stickyNav: function() {
        var headerTop = document.getElementsByClassName("navigation--top");
        var headerMiddle = document.getElementsByClassName("navigation--middle");
        var sticky = headerTop[0].offsetTop;

        var container = document.getElementsByClassName('sticky-nav-div');
        
        if (window.pageYOffset > sticky) {
            headerTop[0].classList.add("sticky-nav");
            headerMiddle[0].classList.add("sticky-nav");

            container[0].classList.remove('hidden');
        }
        window.addEventListener('scroll', function() {
            if (window.pageYOffset > sticky) {
                headerTop[0].classList.add("sticky-nav");
                headerMiddle[0].classList.add("sticky-nav");
                container[0].classList.remove('hidden');
              } else {
                headerTop[0].classList.remove("sticky-nav");
                headerMiddle[0].classList.remove("sticky-nav");
                container[0].classList.add('hidden')
              }
        });
    },

    productListing: function() {
        $('.product-list-filter').ready(function(){
            var mobileFilterDiv = $('.js-mobile-filter__placeholder');
            mobileFilterDiv.append($('.product-list-filter').clone(true));
        });
    },
    clearSearch: function () {
        $(".search-box-close_icon").click(function(){
            $(this).parent().find("input").val('');
            $('.js-search-box-close--icon').addClass('search-box-close_icon--hidden');
        });
        $(".search-box-close_icon").click(function(){
            $(this).parent().find("input").val('');
            $('.js-page-not-found-close--icon').addClass('search-box-close_icon--hidden');
        });
    },
    fixFooter: function() {
        $(document).ready(function() {
            // get footer columns
            var footerCols = $('.footer__top .col-sm-3');
            footerCols = footerCols.sort(function(item1, item2) {
                return parseInt($(item1).data('order')) > parseInt($(item2).data('order')) ? 1 : -1;
            });
            // replace the footer nav container html with the misplaced cols
            $('.footer__nav--container').html(footerCols);
        });
    },
    continueShopping: function() {
		$('.js-continue-shopping-button').on('click', function(e){
            e.preventDefault();
			var checkoutUrl = $(this).data('continueShoppingUrl');
			window.location = checkoutUrl;
		});
    },

    serialize: function (obj) {
        var str = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
        return str.join('&');
    },

    formatCheckoutTotal: function() {
        var cartTotal = $(".checkout-cart-summary .price").first().text();
        var cartTotalLength = cartTotal.replace(/[^0-9]/g,"").length;
        if (cartTotalLength >= 9) {
            $(".checkout-cart-summary .price").addClass("price-mobile")
        }
    },

    dynamicLink: function(){
        var dynamicButton = $('.search_opc_button');
        var promoCalendarURL = ACC.urls.promoCalendar;
        var searchOpcURL = ACC.urls.searchOPC;

        $(document).ready(function(){
            if($(dynamicButton).length){
                $(dynamicButton ).prop( "disabled", false );
                ACC.loader.showLoadingOnButton(dynamicButton);
                $.ajax({
                    url: searchOpcURL,
                    type: 'GET',
                    success: function(data) {
                        if (data != "") {
                            $('.js-input-dynamic').attr({
                                value  : data});
                        } else {
                            $('.dynamicLink__error').removeClass('hidden');
                            $(dynamicButton ).prop( "disabled", true );
                        }
                        ACC.loader.hideLoadingOnButton(dynamicButton);
                    },
                    error: function(e){
                        console.error("ERROR : " + e);
                    }
                });
            }
        });
    },

    heroBannerCarousel: function(){
        var carousel = $('.hero-banner-carousel')
        carousel.ready(function(){
            var timeout = $('.hero-banner-carousel').data("timeout") ? $('.hero-banner-carousel').data("timeout")*1000 : 5000;
            var speed = $('.hero-banner-carousel').data("transition-speed") ? $('.hero-banner-carousel').data("transition-speed")*1000 : 2000;
            var settings = {
                items: 1,
                nav: false,
                autoplay: true,
                autoplayTimeout: timeout,
                responsive: {},
                autoplaySpeed: speed,
                navSpeed: speed,
                dotsSpeed: speed,
                loop: true,
                onChange: function(){
                    carousel.trigger('stop.owl.autoplay')
			        setTimeout(function() {carousel.trigger('play.owl.autoplay')}, speed/2) 
                }
              };
              // desktop device settings (768px +)
            settings.responsive[parseInt(screenMd)] = {
              nav: true,
              navText: [
                '<svg class="icon gallery-arrow-left"><use xlink:href="#gallery-arrow-left" /></svg>',
                '<svg class="icon gallery-arrow-right"><use xlink:href="#gallery-arrow-right" /></svg>'
              ],
              dots: true,
              dotsEach: true,
            };
            $(".owl-carousel").owlCarousel(settings);
        });
    },

    bindShopListOnfocus: function () {
        if ($('.shop-list-search__product-container').length > 0) {
            $('.shop-list-search__wrapper').addClass('shop-list-search__wrapper--open');
        }
    },

    bindShopListOnfocusOut: function () {
        $(document).on('click', function(event){
            if (!$(event.target).hasClass('js-shop-product')) {
                $('.shop-list-search__wrapper').removeClass('shop-list-search__wrapper--open');
            }
        });
    }
};
