/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckoutSteps = function () {
  function CheckoutSteps() {
    _classCallCheck(this, CheckoutSteps);
  }

  _createClass(CheckoutSteps, [{
    key: 'bindDeliveryMethodChange',
    value: function bindDeliveryMethodChange() {
      $(document).on('click', '.delivery-method-radio', function () {
        var deliveryMethod = $(this).data('delivery-method');
        if (deliveryMethod) {
          $('#overrideParams').val(deliveryMethod);
        }
      });
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var that = this,
          form = '#checkoutFormToSubmit';

      $(form).validate({
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          $(element).closest('.form-group').addClass('has-error');
          $(element).addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          $(element).closest('.form-group').removeClass('has-error');
          $(element).removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          $(element).parents(".form-group").after(error);
        },
        rules: {
          deliveryInstructions: { alphanumeric: true }
        }
      });
    }
  }]);

  return CheckoutSteps;
}();

$(function () {
  var checkout = new CheckoutSteps();
  if ($('.checkout-steps').length > 0) {
    checkout.bindDeliveryMethodChange();
    checkout.validateForm();
  }
});
