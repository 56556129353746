/*Babel ES6 transpiled!*/"use strict";

ACC.enquirymapping = {

    _autoload: [
        // ["addProductTemplate", $('.send-enquiry--b2b').length != 0]
    ],
    whereToFindURL: (document.getElementById("whereToFindURL") || {}).innerHTML,
    whatHappensNextURL: (document.getElementById("whatHappensNextURL") || {}).innerHTML,
    formEnquiryType2: (document.getElementById("formEnquiryType2") || {}).innerHTML,
    formEnquiryProducts: (document.getElementById("enqProductList") || {}).innerHTML,

    keyValues: {
        ENQUIRY_TYPE_VALUE_BLANK: "-",
        QUALITY_ISSUE_PRODUCT: "I have a quality issue with a product",
        PRODUCT_KEG: "11",
        PRODUCT_KEG_VALUE: "Product is a keg",
        PRODUCT_NOT_KEG: "12",
        PRODUCT_NOT_KEG_VALUE: "Product is not a keg",

        EMPTY_KEGS: "I have empty kegs for pickup",
        EMPTY_KEGS_SUBTYPE: "21",

        FEEDBACK_ON: "I have feedback on...",
        DELIVERY_EXPERIENCE: "31",
        DELIVERY_EXPERIENCE_VALUE: "My delivery experience",
        PRODUCT: "32",
        PRODUCT_VALUE: "A Product",
        PROMO_ADS: "33",
        PROMO_ADS_VALUE: "Promotions/Advertising",
        GENERAL: "34",
        GENERAL_VALUE: "General/Not Listed",
        SPONSORSHIP: "35",
        SPONSORSHIP_VALUE: "A Sponsorship",
        LION: "36",
        LION_VALUE: "Lion",
        THIS_PORTAL: "37",
        THIS_PORTAL_VALUE: "This Portal",

        NEW_FRIDGE: "I would like a new fridge",
        NEW_FRIDGE_SUBTYPE: "41",

        FRIDGE_MAINTENANCE: "I need maintenance on my fridge",
        FRIDGE_MAINTENANCE_SUBTYPE: "51",

        ORDER_RETURN: "I need an order return or partial order return",
        ORDER_RETURN_SUBTYPE: "61",
        ORDER_RETURN_SUBTYPE_VALUE: "-"
    },

    enquiryTypes: function /*Babel ES6 transpiled!*/enquiryTypes() {
        return {
            QUALITY_ISSUE_PRODUCT: {
                PRODUCT_KEG: ACC.enquirymapping.keyValues.PRODUCT_KEG_VALUE,
                PRODUCT_NOT_KEG: ACC.enquirymapping.keyValues.PRODUCT_NOT_KEG_VALUE
            },
            EMPTY_KEGS: { EMPTY_KEGS_SUBTYPE: ACC.enquirymapping.keyValues.ENQUIRY_TYPE_VALUE_BLANK },
            FEEDBACK_ON: {
                DELIVERY_EXPERIENCE: ACC.enquirymapping.keyValues.DELIVERY_EXPERIENCE_VALUE,
                PRODUCT: ACC.enquirymapping.keyValues.PRODUCT_VALUE,
                PROMO_ADS: ACC.enquirymapping.keyValues.PROMO_ADS_VALUE,
                GENERAL: ACC.enquirymapping.keyValues.GENERAL_VALUE,
                SPONSORSHIP: ACC.enquirymapping.keyValues.SPONSORSHIP_VALUE,
                LION: ACC.enquirymapping.keyValues.LION_VALUE,
                THIS_PORTAL: ACC.enquirymapping.keyValues.THIS_PORTAL_VALUE
            },
            NEW_FRIDGE: { NEW_FRIDGE_SUBTYPE: ACC.enquirymapping.keyValues.ENQUIRY_TYPE_VALUE_BLANK },
            FRIDGE_MAINTENANCE: { FRIDGE_MAINTENANCE_SUBTYPE: ACC.enquirymapping.keyValues.ENQUIRY_TYPE_VALUE_BLANK },
            ORDER_RETURN: { ORDER_RETURN_SUBTYPE: ACC.enquirymapping.keyValues.ENQUIRY_TYPE_VALUE_BLANK }
        };
    },

    enquiryFrontend: {
        QUALITY_ISSUE_PRODUCT: {
            className: 'quality-issue',
            heading: 'Product Details',
            messageRequired: true,
            QUALITY_ISSUE_PRODUCT: {
                PRODUCT_KEG: {
                    className: 'quality-issue--keg'
                },
                PRODUCT_NOT_KEG: {
                    className: 'quality-issue--not-keg'
                }
            }
        },
        EMPTY_KEGS: {
            className: 'pickup',
            heading: 'Keg Details',
            messageRequired: false
        },
        FEEDBACK_ON: {
            className: 'feedback',
            heading: 'Feedback Details',
            messageRequired: true,
            FEEDBACK_ON: {
                PRODUCT: {
                    className: 'feedback--product'
                }
            }
        },
        NEW_FRIDGE: {
            className: 'new-fridge',
            heading: '',
            messageRequired: true
        },
        FRIDGE_MAINTENANCE: {
            className: 'fridge-maintenance',
            heading: '',
            messageRequired: true
        },
        ORDER_RETURN: {
            className: 'return',
            heading: 'Order Details',
            messageRequired: false,
            ORDER_RETURN: {
                ORDER_RETURN_SUBTYPE: {
                    className: 'return--product'
                }
            }
        },
        DAMAGED_STOCK: {
            className: 'return',
            heading: 'Damaged Stock',
            messageRequired: false,
            DAMAGED_STOCK: {
                DAMAGED_STOCK_SUBTYPE: {
                    className: 'return--product'
                }
            }
        }
    }
};
