/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var addEditVenueEmployeeFormId = '#add-edit-venue-employee-form';

/*Babel ES6 transpiled!*/var ManageStaff = function () {
  function ManageStaff() {
    _classCallCheck(this, ManageStaff);
  }

  _createClass(ManageStaff, [{
    key: 'bindAddEditVenueEmployeePopup',
    value: function bindAddEditVenueEmployeePopup() {
      var that = this;
      $(document).on('click', '.js-add-new-employee', function (e) {
        e.preventDefault();
        var isEditMode = $(this).hasClass('js-edit-address');
        ACC.colorbox.open({
          href: $(this).data('link'),
          width: '709px',
          height: false,
          fixed: false,
          top: false,
          escKey: false,
          overlayClose: false,
          className: 'add-venue-container',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            that.bindFormEvents();
            $.colorbox.resize();
            $(document).on('click', '.js-venue-cancel', function () {
              ACC.colorbox.close();
            });

            $(document).on('keyup', '#add-edit-venue-employee-form', function () {
              $.colorbox.resize();
            });

            $(document.body).on('touchmove', function () {
              $.colorbox.resize();
            });
          }
        });
      });
    }
  }, {
    key: 'bindFormEvents',
    value: function bindFormEvents() {
      this.initializeForm();
      this.bindFormValidation();
    }
  }, {
    key: 'initializeForm',
    value: function initializeForm() {
      $(addEditVenueEmployeeFormId).find('input').each(function () {
        $(this).trigger('focus');
        $(this).trigger('blur');
      });
    }
  }, {
    key: 'bindFormValidation',
    value: function bindFormValidation() {
      $(addEditVenueEmployeeFormId).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          var $element = $(element);
          $element.closest('.form-group').addClass('has-error');
          $element.addClass('has-error').removeClass('is-valid');
          $.colorbox.resize();
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          var $element = $(element);
          $element.closest('.form-group').removeClass('has-error');
          $element.removeClass('has-error').addClass('is-valid');
          $.colorbox.resize();
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error modal-error-message',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          error.insertAfter(element.parent());
          $.colorbox.resize();
        },
        rules: {
          firstName: { lionname: true },
          lastName: { lionname: true }
        }
      });
    }
  }, {
    key: 'bindRemoveStaff',
    value: function bindRemoveStaff() {
      $(".js-remove-staff").click(function (e) {
        e.preventDefault();
        var removeUrl = $(this).data("remove-url");
        var modal = $(this).data("link");
        interrupt = function /*Babel ES6 transpiled!*/interrupt() {
          ACC.colorbox.open({
            href: modal,
            width: "709px",
            height: false,
            fixed: true,
            top: false,
            escKey: false,
            overlayClose: false,
            className: 'confirm-venue-container variantSelectMobile',
            onComplete: function /*Babel ES6 transpiled!*/onComplete() {
              $('.add-edit-employee').addClass("hide");
              $('.remove-venue-employee-error').removeClass("hide");
              $.colorbox.resize();
              $(".js-venue-back").click(function () {

                ACC.colorbox.close();
              });
            }
          });
        };
        ACC.colorbox.open({
          href: $(this).data("link"),
          width: "709px",
          height: false,
          fixed: true,
          top: false,
          escKey: false,
          overlayClose: false,
          className: 'variantSelectMobile',
          onComplete: function /*Babel ES6 transpiled!*/onComplete() {
            $('.add-edit-employee').addClass("hide");
            $('.remove-venue-employee').removeClass("hide");
            $(".js-venue-remove").click(function (e) {
              $.ajax({
                url: removeUrl,
                type: "POST",
                success: function /*Babel ES6 transpiled!*/success(response) {
                  if (response === "ERROR") {
                    ACC.colorbox.close();
                    setTimeout(function () {
                      interrupt();
                    }, 1000);
                  } else {
                    window.location.reload();
                  }
                }
              });
            });
            $(".js-venue-cancel").click(function () {
              ACC.colorbox.close();
            });
            $.colorbox.resize();
          }
        });
      });
    }
  }, {
    key: 'bindStaffFormSubmit',
    value: function bindStaffFormSubmit() {
      var that = this;
      $(document).on('submit', addEditVenueEmployeeFormId, function (event) {
        event.preventDefault(); //prevent default action 

        var saveButton = '.save';
        // show loading indicator on save button
        ACC.loader.showLoadingOnButton(saveButton);

        var post_url = $(this).attr("action"); //get form action url
        var return_url = $(this).attr("returnurl"); //get form action url
        var request_method = $(this).attr("method"); //get form GET/POST method
        var form_data = $(this).serialize(); //Encode form elements for submission
        var sectionToReplace = addEditVenueEmployeeFormId;
        $.ajax({
          url: post_url,
          type: request_method,
          data: form_data
        }).done(function (response) {
          if ($(response).find('.form-error-message').length > 0) {
            // hide loading indicator
            ACC.loader.hideLoadingOnButton(saveButton);
            $(sectionToReplace).replaceWith($(response));
            that.bindFormEvents();
            $.colorbox.resize();
          } else {
            window.location.replace(return_url);
          }
        });
      });
    }
  }]);

  return ManageStaff;
}();

$(function () {
  var manageStaff = new ManageStaff();
  if ($('.my-staff').length > 0) {
    manageStaff.bindAddEditVenueEmployeePopup();
    manageStaff.bindFormValidation();
    manageStaff.bindFormEvents();
    manageStaff.bindRemoveStaff();
    manageStaff.bindStaffFormSubmit();
  }
});
