/*Babel ES6 transpiled!*/"use strict";

ACC.stickyheader = {
	init: function /*Babel ES6 transpiled!*/init() {
		var STICKY_TIMEOUT = 1000;
		setTimeout(function () {
			ACC.stickyheader.createSticky();
		}, STICKY_TIMEOUT);
	},

	createSticky: function /*Babel ES6 transpiled!*/createSticky() {
		var sticky = $(".sticky-element"),
		    win = $(window),
		    stickyContainer = $(".sticky-element__container");
		headerHeight = $('.navigation--top').height() + $('.navigation__container--bottom').height();

		win.on("scroll", function () {
			var pos = (sticky.offset() || {}).top || 0,
			    outerHeight = stickyContainer.length ? stickyContainer.outerHeight() - headerHeight : sticky.outerHeight() - headerHeight,
			    bottom = pos + outerHeight,
			    stickyContainerWidth = stickyContainer.width();
			win.scrollTop() >= pos - headerHeight && win.scrollTop() <= bottom ? sticky.addClass("fixed-element").parent().addClass('sticky-table') : sticky.removeClass("fixed-element").parent().removeClass('sticky-table');
			$('.fixed-element tr').width(stickyContainerWidth);
		});
	}
};

$(function () {
	ACC.stickyheader.init();
});
