/*Babel ES6 transpiled!*/'use strict';

var enquirySearch = {
  api: '/search/autocomplete/SearchBox',
  container: $('.enquiry__product-search__container'),
  products: [],

  /**
   * Code that runs on page load
   */
  init: function /*Babel ES6 transpiled!*/init() {

    /* Add Event listener for every product lookup field */
    var containers = Array.from(enquirySearch.container);
    containers.forEach(function (item) {
      var productSearch = $(item).find('.enquiry__product-search')[0];
      var productCode = $(item).find('.enquiry__product-code')[0];
      var suggestions = $(item).find('.suggestions')[0];

      productSearch.addEventListener('keyup', enquirySearch.searchHandler);
      suggestions.addEventListener('click', enquirySearch.useSuggestion);
    });

    document.addEventListener('click', enquirySearch.clickOutsideHandler);
  },

  /**
   * Get search results from API
   */
  getSearchResults: function /*Babel ES6 transpiled!*/getSearchResults(str, container) {
    // {base}/search/autocomplete/SearchBox?term=beer

    var apiUrl = enquirySearch.api;

    $.get(apiUrl, {
      term: str
    }, function (data) {
      var results = data.products.map(function (product) {
        return {
          name: product.name,
          code: product.code
        };
      });
      /* Add the results into the products list */
      enquirySearch.products = results;
      /* Pass the results on for display */
      enquirySearch.showSuggestions(results, str, container);
    });
  },

  /**
   * Handler for getting the user's search string
   */
  searchHandler: function /*Babel ES6 transpiled!*/searchHandler(e) {
    var inputVal = e.currentTarget.value;
    var container = $(e.currentTarget).closest('.enquiry__product-search__container');

    /* Input length must be more than 2 characters before you can search */
    if (inputVal.length > 2) {
      enquirySearch.getSearchResults(inputVal, container);
    } else {
      enquirySearch.resetResults(container);
    }
  },

  /**
   * Handler for getting the user's search string
   */
  showSuggestions: function /*Babel ES6 transpiled!*/showSuggestions(results, str, container) {
    // Soft reset
    var suggestions = container.find('.suggestions');
    suggestions.html('');

    // If there are results, show the results
    if (results.length > 0) {
      var list = '';
      for (i = 0; i < results.length; i++) {
        // Substring highlighting
        var regex = new RegExp('(' + str.split(' ').join('|') + ')', 'gi');
        var resultHighlighted = results[i].name.replace(regex, '<strong>$1</strong>');
        list += /*Babel ES6 transpiled!*/'<li>' + resultHighlighted + '</li>';
      }
      suggestions.html( /*Babel ES6 transpiled!*/'<ul>' + list + '</ul>');
      suggestions.addClass('has-suggestions');
      // Else, reset the results
    } else {
      enquirySearch.resetResults(container);
    }
  },

  /**
   * Handler for getting the user selected suggestion
   */
  useSuggestion: function /*Babel ES6 transpiled!*/useSuggestion(e) {
    var container = $(e.currentTarget).closest('.enquiry__product-search__container');
    var input = container.find('#productSearch');
    var value = /*Babel ES6 transpiled!*/void 0;

    // If the target was the highlighted substring, get the parent's value
    if (e.target.tagName === 'STRONG') {
      value = e.target.parentElement.innerText;
      // Else just get the value
    } else {
      value = e.target.innerText;
    }

    input[0].value = value;
    input[0].focus();
    enquirySearch.populateProductCode(value, container);
  },

  /**
   * Populate the hidden Product Code field based on the product name selected
   */
  populateProductCode: function /*Babel ES6 transpiled!*/populateProductCode(value, container) {
    var input = container.find('#productCode');
    var product = enquirySearch.products.find(function (product) /*Babel ES6 transpiled!*/{
      return product.name === value;
    });
    if (product) {
      input[0].value = product.code;
      enquirySearch.resetResults(container);
    } else {
      console.error('Error populating the product code field. There may be a whitespace issue when trying to match the product name.');
    }
  },

  /**
   * Reset the results array and the suggestions list
   */
  resetResults: function /*Babel ES6 transpiled!*/resetResults(container) {
    var suggestions = container.find('.suggestions');
    enquirySearch.products = [];
    suggestions.html('');
    suggestions.removeClass('has-suggestions');
    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').addClass('hidden');
    $('.enquiry__product-code.has-error').closest('.row').find('#productCode-error').removeClass('visible');
  },

  /**
   * Reset the results when user clicks outside the bounds
   */
  clickOutsideHandler: function /*Babel ES6 transpiled!*/clickOutsideHandler(e) {
    var containerCheck = $(e.target).closest('.enquiry__product-search__container');
    var hasSuggestions = $('.suggestions').hasClass('has-suggestions');
    if (containerCheck.length === 0 && hasSuggestions) {
      var container = $('.has-suggestions').closest('.enquiry__product-search__container');
      enquirySearch.resetResults(container);
    }
  },

  /**
   * Validate the Product Name and Product Code fields
   */
  validateProduct: function /*Babel ES6 transpiled!*/validateProduct(name, code) {
    // Validate that the product code input has a value.
    if (code === '') {
      enquirySearch.handleValidationError('productName', name);
      return false;
    }

    ACC.enquiryb2b.submitForm();
    return true;
  },

  /**
   * When fields are invalid, display an error message.
   */
  handleValidationError: function /*Babel ES6 transpiled!*/handleValidationError(fieldName, value) {
    var field = $(Array.from($( /*Babel ES6 transpiled!*/'[name="' + fieldName + '"]')).find(function (field) /*Babel ES6 transpiled!*/{
      return field.value === value;
    }));
    field.addClass('input-error');
    field.removeClass('is-valid');
    field.attr('aria-invalid', 'true');
    var fieldContainer = field.closest('.form-group');
    if (fieldContainer) {
      fieldContainer.addClass('has-error').removeClass('is-valid');
    }
    var row = field.closest('.row');
    var msgContainer = row.find('.input-msg-group');
    var errorText = /*Babel ES6 transpiled!*/'<div id="productSearch-error" class="input-msg input-error">' + ACC.sendEnquiry.productSearchErrorText + '</div>';

    msgContainer.html(errorText);
  }
};
