ACC.listpickupinstoreaction = {

}

$(document).ready(function ()
{
	with (ACC.listpickupinstoreaction)
	{

	}
});


