ACC.changevenuepopup = {

    _autoload: [
        "bindLink",
        "bindConfirm",
        "toggleDropdown"
    ],
    selectedVenue: "",

    // Toggle the Change Venue dropdown menu on desktop header
    toggleDropdown: function() {
        $(document).on('click', '.js-change-venue-toggle', function() {
            if (!$(this).hasClass('is-active')) {
                ACC.changevenuepopup.getVenueInformation();
            }

            $(this).toggleClass('is-active');
        });

        $(document).on('click', function(e) {
            var dropdownContainer = $('.js-user-dropdown__container')
            var venueContainer = $('.js-change-venue-toggle')  
            if (((!dropdownContainer.is(e.target) && dropdownContainer.has(e.target).length === 0) && (!venueContainer.is(e.target) && venueContainer.has(e.target).length === 0))
            && (venueContainer).hasClass('is-active')) {
                venueContainer.removeClass('is-active')
            }
        });
    },

    bindLink: function(){
        $(document).on("click",".btn-change-venue, .btn-change-store",function(e){
            e.preventDefault();

            ACC.colorbox.open({
                    href: $(this).data("link"),
                    width:"709px",
                    height: false,
                    fixed: true,
                    top: false,
                    className: 'change-venue-container variantSelectMobile',
                    onOpen: function()
                    {
                        $('#validEmail').remove();
                    },
                    onComplete: function(){
                        $("#change-venue-form").removeClass("hide");
                        $.colorbox.resize();
                    }
                }
            );
        });
    },

    bindConfirm: function(){
        $(document).on("click",".btn-change-venue-confirm",function(e){
            e.preventDefault();
            var venue = $(this).data("venue");
            var venueName = venue;
            //in case venue names have special characters
            venueName = venueName.replace(/"/g, '&quot;');
            venueName = venueName.replace(/'/g, '&#39;');
            venueName = venueName.replace(/</g, '&lt;');
            venueName = venueName.replace(/>/g, '&gt;');
            ACC.colorbox.open({
                href: $(this).data("link"),
                width:"709px",
                height: false,
                fixed: true,
                top: false,
                className: 'confirm-venue-container variantSelectMobile',
                onOpen: function()
                {
                    $('#validEmail').remove();
                },
                onComplete: function(){
                    $('.change-venue').addClass('hide');
                    $("#change-venue-form").addClass("hide");
                    $("#confirm-venue-form").removeClass("hide");
                    $(".venue-confirm").removeClass("hide");
                    $('.sel-venue').html('<b class="text-style-1">' +venueName+'</b>');
                    $(document).on("click",".js-btnCancel", function (e){
                        ACC.colorbox.close();
                    });
                    $(document).on("click",".js-btnContinue", function (e){
                        ACC.changevenuepopup.bindVenueSelection(venue);
                    });
                    $.colorbox.resize();
                }
            });
        });
    },

    bindVenueSelection: function (value) {

        var venueUid = encodeURIComponent(value);
        $.ajax({
            url:"/changeVenue/setVenue",
            type: 'POST',
            data: {uid: venueUid},
            success: function(data){
                location.reload();
            },
            error: function(e){
                console.log("ERROR : " + e);
            }
        });
    },

    modalChangeVenue: function() {
        var input, filter, ul, li, a, i;
        input = document.getElementById("modal-change-venue");
        filter = input.value.toUpperCase();
        ul = document.getElementById("modal-change-venue-ul");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";

            }
        }
    },

    getVenueInformation: function() {
        var cutOffDiv = '.js-drop-down-cutoff';
        var $cutOffText = $(cutOffDiv);
        // initially show the cut off time div
        $cutOffText.show();
        // show a loader while fetching data
        ACC.loader.showLoadingOnDiv(cutOffDiv);
        $.ajax({
            url:"/my-venue/getCutOffDate",
            type: 'GET',
            success: function(data) {
                // hide loader after a delay
                setTimeout(function() {
                    ACC.loader.hideLoadingOnDiv(cutOffDiv);
                }, 600);
                if (data) {
                    if(data.cutOffDate) {
                        // show the cutoff date
                       $('.js-cut-off-date').text(data.cutOffDate + ".");
                    } else {
                        // hide the cutoff date div
                        $cutOffText.fadeOut(600);
                    }

                    if(data.multipleVenueFlag && !data.isEditOrder) {
                        // show the change venue button
                        $('.js-change-venue-btn').removeClass('hidden');
                    }
                }
            },
            error: function(e){
                console.error("ERROR : " + e);
            }
        });
    },
};