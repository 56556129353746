/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ResetPassword = function () {
  function ResetPassword() {
    _classCallCheck(this, ResetPassword);
  }

  _createClass(ResetPassword, [{
    key: 'init',
    value: function init() {
      this.validateForm();
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var form = '#updatePwdForm';
      $(form).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        rules: {
          pwd: { lionpassword: true },
          checkPwd: { confirmpassword: true }
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          $(element).closest('.form-group').addClass('has-error');
          $(element).addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          $(element).closest('.form-group').removeClass('has-error');
          $(element).removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          var row = $(element).parents()[2];
          var msgGroup = $(row).find('.input-msg-group--bottom');
          $(msgGroup).append(error);
        },
        messages: {
          checkPwd: {
            confirmpassword: ACC.messageLabels.passwordNotMatchError
          }
        }
      });
    }
  }]);

  return ResetPassword;
}();

$(function () {
  var container = '.reset-password';
  var resetPassword = new ResetPassword();
  if ($(container).length > 0) {
    resetPassword.init();
  }
});
