ACC.addtocartaction = {

}

$(document).ready(function ()
{
	with (ACC.addtocartaction)
	{

	}
});


