ACC.fnfInvite = {
    _autoload: [
        ["bindValidation", $('.account-invite__content').length != 0]
    ],
    form: '#accountInviteForm',
    $form: $('#accountInviteForm'),

    validateForm: function(){
        var emailRule = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
        var myEmail = $('#inviteeEmail').val();

        if (!emailRule.test(myEmail)) {
            if ($.trim(myEmail).length === 0) {
                $('#blankEmail').removeClass('hidden');
                $('#invalidEmail').addClass('hidden');
                $("input[name=inviteeEmail]").parent().addClass('has-error');
                $("input[name=inviteeEmail]").addClass('has-error');
            } else {
                $('#invalidEmail').removeClass('hidden');
                $('#blankEmail').addClass('hidden');
                $("input[name=inviteeEmail]").parent().addClass('has-error');
                $("input[name=inviteeEmail]").addClass('has-error');
            }
        } else {
            $('#invalidEmail').addClass('hidden');
            $('#blankEmail').addClass('hidden');
            $("input[name=inviteeEmail]").parent().removeClass('has-error');
            $("input[name=inviteeEmail]").removeClass('has-error');
        }

    },

    bindValidation: function() {
        // override html5 email validation
        $("#inviteeEmail").on('blur', ACC.fnfInvite.validateForm);
    },
    
    submitForm: function() {
        ACC.fnfInvite.validateForm();
        var formData = ACC.fnfInvite.$form.serialize();
        var isNotValid = $("input[name=inviteeEmail]").hasClass('has-error');

        if(!isNotValid){
            $.ajax({
                url: ACC.urls.inviteURL,
                type: 'POST',
                data: formData,
                success: function (data) {
                    if (data) {
                        $('.invite-error').addClass('hidden');
                        $('.invite-success').removeClass('hidden');
                        $('#inviteeEmail').val("").focus();
                        setTimeout(function () {
                            $('.invite-success').addClass('hidden');
                        }, 5000);
                    }
                    else {
                        console.log('error');
                        $('.invite-error').removeClass('hidden');
                        $('#inviteeEmail').val("").focus();
                    }
                },
                error: function() {
                    console.log('error');
                    $('.invite-error').removeClass('hidden');
                    $('#inviteeEmail').val("").focus();
                },
            });
        }

    },
};