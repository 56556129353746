/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CustomerRegistration = function () {
  function CustomerRegistration() {
    _classCallCheck(this, CustomerRegistration);
  }

  _createClass(CustomerRegistration, [{
    key: 'init',
    value: function init() {
      this.validateForm();
      this.checkboxToggleHandler();
      this.bindAddressAutoComplete();
      this.checkboxContactHandler(); // toggle contact details field
      this.goToStepHandler(); // submit form while going to a step in the registration process
      this.checkboxLiquorLicenseHandler(); // toggle liquor license fields
      this.checkboxOpenedDateHandler(); // toggle liquor open date field hide/show
      this.ownershipGroupHandler(); // toggle the "other" field when other is selected as a value in a dropdown
      this.initDatepicker(); // initialise the datepicker on date selection fields
    }
  }, {
    key: 'checkboxToggleHandler',
    value: function checkboxToggleHandler() {
      var existingCustomer = $('[name="existingCustomer"]');
      existingCustomer.on('change', this.checkboxToggle);
    }
  }, {
    key: 'checkboxToggle',
    value: function checkboxToggle(e) {
      var checked = e.target.checked;
      var accountFieldset = $('.registration-form__account');

      if (checked) {
        accountFieldset.addClass('is-active');
      } else {
        accountFieldset.removeClass('is-active');
      }
    }
  }, {
    key: 'checkboxContactHandler',
    value: function checkboxContactHandler() {
      var chkContact = $('.js-same-address');
      chkContact.on('change', function (e) {
        var checked = e.target.checked;
        var manualEntrySelector = '.manual-entry-details';
        var manualEntryForm = $(manualEntrySelector);
        var existingDetails = $('.existing-contact-details');

        if (checked) {
          existingDetails.removeClass('hide');
          manualEntryForm.addClass('hide');
        } else {
          existingDetails.addClass('hide');
          manualEntryForm.removeClass('hide');
          ACC.utilities.clearFormFields(manualEntrySelector);
        }
      });
    }
  }, {
    key: 'checkboxLiquorLicenseHandler',
    value: function checkboxLiquorLicenseHandler() {
      this.showHideCheckboxHandler('#alcoholPurchasing', '.liquor-license-fields');
    }
  }, {
    key: 'checkboxOpenedDateHandler',
    value: function checkboxOpenedDateHandler() {
      this.showHideCheckboxHandler('#opened', '.expected-open-date');
    }
  }, {
    key: 'showHideCheckboxHandler',
    value: function showHideCheckboxHandler(checkboxSelector, elementToToggleSelector) {
      var chkBox = $(checkboxSelector);
      chkBox.on('change', function (e) {
        var checked = e.target.checked;
        var elementToToggle = $(elementToToggleSelector);

        if (checked) {
          elementToToggle.removeClass('hide');
        } else {
          elementToToggle.addClass('hide');
        }
      });
    }
  }, {
    key: 'goToStepHandler',
    value: function goToStepHandler() {
      var stepBtn = $('.step-button');
      stepBtn.on('click', function () {
        var form = '.registration-form';
        var step = $(this).data('step');
        if ($(form).valid()) {
          // append step name
          $('<input />').attr('type', 'hidden').attr('name', 'goToStep').attr('value', step).appendTo(form);

          $(form).submit();
        }

        return false;
      });
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var form = '.registration-form';
      $(form).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          if ($(element).attr('type') !== 'file') this.element(element);
        },
        rules: {
          phone: {
            digits: true,
            require_from_group: [1, '.phone-group']
          },
          mobileNumber: {
            digits: true,
            require_from_group: [1, '.phone-group']
          },
          landLine: {
            digits: true,
            require_from_group: [1, '.phone-group']
          },
          deliveryContact: { digits: true },
          suburb: { lettersonly: true },
          firstName: { lionname: true },
          lastName: { lionname: true },
          townCity: { town: true },
          liquorLicenseNumber: { alphanumeric: true },
          liquorLicences: { fileupload: true },
          postcode: {
            digits: true,
            minlength: 4,
            min: parseInt($('#postcode').attr("minValue")),
            max: parseInt($('#postcode').attr("maxValue"))
          },
          // FF registration fields:
          pwd: { lionpassword: true },
          checkPwd: { confirmpassword: true },
          dateOfBirth: { agecheck: true }
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          $(element).closest('.form-group').addClass('has-error');
          $(element).addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          $(element).closest('.form-group').removeClass('has-error');
          $(element).removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          if (element[0].type === 'file') {
            $('.upload-attachment-msg').hide();
            $('#uploadAttachmentErrorRequired').show();
            return;
          }

          if ($(element).parents('.password-field').length) {
            var _msgGroup = $(element).parents('.password-field').next('.input-msg-group');
            $(_msgGroup).append(error);
            return;
          }

          var row = $(element).parents()[2];
          var msgGroup = $(row).find('.input-msg-group');
          $(msgGroup).append(error);
        },
        messages: {
          phone: {
            digits: ACC.messageLabels.mobileNumber,
            require_from_group: ACC.messageLabels.atLeastOneNumber
          },
          mobileNumber: {
            digits: ACC.messageLabels.mobileNumber,
            require_from_group: ACC.messageLabels.atLeastOneNumber
          },
          landLine: {
            digits: ACC.messageLabels.landline,
            require_from_group: ACC.messageLabels.atLeastOneNumber
          },
          deliveryContact: {
            digits: ACC.messageLabels.deliveryContact
          },
          postcode: {
            number: ACC.messageLabels.postcode,
            digits: ACC.messageLabels.postcode,
            minlength: ACC.messageLabels.invalidpostcode,
            min: ACC.messageLabels.invalidpostcode,
            max: ACC.messageLabels.invalidpostcode
          }
        }
      });
    }
  }, {
    key: 'unbindAddressAutoComplete',
    value: function unbindAddressAutoComplete() {
      if ($('.js-address-search').data('ui-autocomplete') != undefined) {
        jQuery('.js-address-search').autocomplete('destroy');
        jQuery('.js-address-search').removeData('autocomplete');
      }
    }
  }, {
    key: 'bindAddressAutoComplete',
    value: function bindAddressAutoComplete() {
      var that = this;
      $('.js-address-search').autocomplete({
        source: function /*Babel ES6 transpiled!*/source(request, response) {
          $.ajax({
            type: "POST",
            dataType: 'json',
            url: ACC.urls.fetchAddress,
            data: {
              address: $("#address").val()
            },
            success: function /*Babel ES6 transpiled!*/success(data) {
              var responseData = [];
              if (data.addresses && data.addresses.length) {
                responseData = data.addresses.map(function (address) {
                  return {
                    value: address.fullAddress,
                    label: address.fullAddress,
                    id: address.dpid
                  };
                }).slice(0, autoCompleteResultsToShow);
              }

              if (!responseData.length) {
                responseData.push({ value: ' ', label: ACC.addressBook.addressNotFound, id: 0 });
              } else {
                responseData.push({ value: '', label: ACC.addressBook.noMatchingAddress, id: 0 });
              }
              return response(responseData);
            },
            error: function /*Babel ES6 transpiled!*/error(jqXHR, textStatus, errorThrown) {
              // error handling
              console.error(textStatus);
            }
          });
        },
        dataType: "json",
        minLength: 5,
        select: function /*Babel ES6 transpiled!*/select(event, ui) {
          that.selectAddress(ui.item.id);
          that.unbindAddressAutoComplete();
        }
      });
    }
  }, {
    key: 'fetchAddress',
    value: function fetchAddress() {
      var that = this;
      $.ajax({
        type: "POST",
        dataType: 'json',
        url: ACC.urls.fetchAddress,
        data: {
          address: $("#address").val()
        },
        success: that.addressSearchSuccess,
        error: function /*Babel ES6 transpiled!*/error(jqXHR, textStatus, errorThrown) {
          // error handling
          console.error(textStatus);
        }
      });
    }
  }, {
    key: 'selectAddress',
    value: function selectAddress(dpid) {
      if (dpid === 0) {
        this.unbindAddressAutoComplete();
        return;
      }
      var that = this;
      $.ajax({
        type: "POST",
        dataType: 'json',
        url: ACC.urls.addressDetails,
        data: { dpid: dpid },
        success: function /*Babel ES6 transpiled!*/success(result) {
          if (result.details && result.details.length > 0) {
            var addressDetail = result.details[0];
            that.setAndInitField('#address', addressDetail.addressLine1);
            that.setAndInitField('#postcode', addressDetail.postcode);
            that.setAndInitField('#townCity', addressDetail.cityTown);
            that.setAndInitField('#suburb', addressDetail.suburb);
          }
        }
      });
    }
  }, {
    key: 'setAndInitField',
    value: function setAndInitField(id, value) {
      $(id).val(value).change();
      $(id).trigger('blur');
    }
  }, {
    key: 'initDatepicker',
    value: function initDatepicker() {
      var expectedOpenDate = $('#expectedOpenDate');
      var dateOfBirth = $('#dateOfBirth');
      var today = moment().startOf('day');
      var tomorrow = moment().add(1, 'days').startOf('day');

      if (expectedOpenDate.length) {
        expectedOpenDate.datetimepicker({
          format: ACC.dateFormat,
          minDate: tomorrow,
          useCurrent: false
        });
      }

      // Date of Birth field in FF registration
      if (dateOfBirth.length) {
        dateOfBirth.datetimepicker({
          format: ACC.dateFormat,
          maxDate: today,
          useCurrent: false
        });
      }
    }
  }, {
    key: 'ownershipGroupHandler',
    value: function ownershipGroupHandler() {
      this.dropdownOtherHandler('#ownershipGroup', '.ownership-group-other');
    }
  }, {
    key: 'dropdownOtherHandler',
    value: function dropdownOtherHandler(dropdownSelector, elementToToggleSelector) {
      var chkBox = $(dropdownSelector);
      chkBox.on('change', function (e) {
        var value = e.target.value;
        var elementToToggle = $(elementToToggleSelector);

        if (value.toLowerCase() === 'other') {
          elementToToggle.removeClass('hide').addClass('show');
        } else {
          elementToToggle.addClass('hide').removeClass('show');
          elementToToggle.find('input').val('');
        }
      });
    }
  }]);

  return CustomerRegistration;
}();

$(function () {
  var container = '.customer-registration';
  var customerRegistration = new CustomerRegistration();
  if ($(container).length > 0) {
    customerRegistration.init();
  }

  // Initialise file upload function for Step 2 liquor license section.
  var liquorLicense = '.liquor-license-fields';
  if ($(liquorLicense).length > 0) {
    // If the liquor licence fields exists, initialise the file upload.
    var sizeLimit = 2097152; // 2MB
    var fileUpload = new FileUpload('#registration-form', sizeLimit, 2, ['.jpg', '.jpeg', '.png', '.gif', '.pdf']);
    fileUpload.init();

    // If files have been uploaded before, do these things:
    if ($('.file-list--item').length > 0) {

      // Add existing files to the file list.
      $('.file-list--item').each(function () {
        var fileName = $(this).find('.btn').data('fileName');
        fileUpload.fileList.push(fileName);

        // If file limit has already been reached, disable the file upload:
        if (fileUpload.fileList.length === fileUpload.fileQtyLimit) {
          fileUpload.toggleFileUploadMessage('limit', 'show');
          $(fileUpload.uploadInputWrapper).addClass('is-disabled');
        }
      });
    }
  }
});
