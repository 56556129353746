ACC.minicart = {
  updateMiniCartDisplay: function(){
    var miniCartRefreshUrl = $(".js-mini-cart-link").data("miniCartRefreshUrl");
    $.ajax({
      url: miniCartRefreshUrl,
      cache: false,
      type: 'GET',
      success: function(jsonData){
        $countField = $(".js-mini-cart-link .js-mini-cart-count");
        var miniCartCount = jsonData.miniCartCount <= $countField.data('maxCountLimit') ? jsonData.miniCartCount : $countField.data('maxString');
        $countField.text(miniCartCount);
        $(".js-mini-cart-link").toggleClass('disabled', jsonData.miniCartCount === 0);
      }
    });
  }
};