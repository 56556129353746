ACC.viewstoreaction = {

}

$(document).ready(function ()
{
    with (ACC.viewstoreaction)
    {

    }
});