ACC.listaddtocartaction = {

}

$(document).ready(function ()
{
	with (ACC.listaddtocartaction)
	{

	}
});


