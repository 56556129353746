/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ChangePassword = function () {
  function ChangePassword() {
    _classCallCheck(this, ChangePassword);
  }

  _createClass(ChangePassword, [{
    key: 'init',
    value: function init() {
      this.validateForm();
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var form = '#lionUpdatePasswordForm';
      $(form).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
          // Check if there is an existing error message shown on page load
          if ($('.js-curr-password-error').hasClass('input-error--show')) {
            $('.js-curr-password-error').removeClass('input-error--show');
          }
        },
        rules: {
          currentPassword: { required: true },
          newPassword: { lionpassword: true }
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          $(element).closest('.form-group').addClass('has-error');
          $(element).addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          $(element).closest('.form-group').removeClass('has-error');
          $(element).removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          var row = $(element).parents()[2];
          var msgGroup = $(row).find('.input-msg-group');
          $(msgGroup).append(error);
        },
        messages: {
          currentPassword: {
            required: ACC.messageLabels.passwordRequired
          },
          newPassword: {
            required: ACC.messageLabels.passwordFormat,
            lionpassword: ACC.messageLabels.passwordFormat
          }
        }
      });
    }
  }]);

  return ChangePassword;
}();

$(function () {
  var container = '.change-password';
  var changePassword = new ChangePassword();
  if ($(container).length > 0) {
    changePassword.init();
  }
});
