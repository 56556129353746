/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var VenueDetails = function () {
  function VenueDetails() {
    _classCallCheck(this, VenueDetails);
  }

  _createClass(VenueDetails, [{
    key: 'init',
    value: function init() {}
    // this.validateForm();
    // this.editBillingContactHandler();
    // this.cancelBillingContactFormHandler();
    // this.saveBillingContactFormHandler();


    // editBillingContactHandler() {
    //   const that = this,
    //         editContact = $('.js-edit-billing-contact');

    //   editContact.on('click', function () {
    //     that.toggleBillingContactFormHandler();
    //   });
    // }

    // cancelBillingContactFormHandler() {
    //   const that = this,
    //         contactForm = $('.venue-billing-contact-form'),
    //         cancelEditContact = $('.js-cancel-billing-contact');

    //   cancelEditContact.on('click', function () {
    //     if (contactForm.length) {
    //       contactForm[0].reset();
    //     }
    //     that.toggleBillingContactFormHandler();
    //   });
    // }

    // toggleBillingContactFormHandler() {
    //   const billingInfoBox = $('.js-billing-contact-box'),
    //         contactForm = $('.venue-billing-contact-form'),
    //         errorMsg = $('.js-details-error-message');

    //   contactForm.toggleClass('hidden');
    //   billingInfoBox.toggleClass('hidden');
    //   errorMsg.addClass('hidden');
    // }

    // saveBillingContactFormHandler() {
    //   const saveContactBtn = $('#saveBillingContact'),
    //         contactForm = $('.venue-billing-contact-form');
    //   saveContactBtn.on('click', function () {
    //     contactForm.valid();
    //   });
    // }

    // validateForm() {
    //   const that = this,
    //     form = '.venue-billing-contact-form',
    //     successMsg = $('.venue-billing-contact-form__success'),
    //     errorMsg = $('.js-details-error-message');
    //   $(form).validate({
    //     rules: {
    //       phone: {
    //         countryCodePhoneNumber: true,
    //         phonenumber: true,
    //         maxlength: 13,
    //       },
    //       email: {
    //           email: true,
    //           maxlength: 60,
    //       }
    //     },
    //     highlight: function (element) {
    //       $(element).closest('.form-group').addClass('has-error');
    //       $(element).addClass('has-error').removeClass('is-valid');
    //     },
    //     unhighlight: function (element) {
    //       $(element).closest('.form-group').removeClass('has-error');
    //       $(element).removeClass('has-error').addClass('is-valid');
    //     },
    //     errorElement: 'div',
    //     errorClass: 'input-msg input-error',
    //     errorPlacement: function (error, element) {
    //       const row = $(element).parents()[2];
    //       const msgGroup = $(row).find('.input-msg-group');
    //       $(msgGroup).append(error);

    //     },
    //     messages: {
    //       phone: {
    //         phonenumber: ACC.messageLabels.mobileNumber,
    //         require_from_group: ACC.messageLabels.atLeastOneNumber,
    //         maxlength: 	ACC.messageLabels.length,
    //       },
    //     },
    //     submitHandler: function (form) {
    //       const firstName = $("#firstName").val(),
    //         lastName = $("#lastName").val(),
    //         email = $("#email").val(),
    //         phone = $("#phone").val(),
    //         landline = $("#landline").val();
    //       const successTimeout = 3000,
    //             ajaxTimeout = 600000
    //             fadeOutTime = 1000;

    //       const data = { firstName, lastName, email, phone, landline };

    //       $("#saveBillingContact").prop("disabled", true);
    //       $.ajax({
    //         type: "POST",
    //         contentType: "application/json",
    //         url: "/my-venue/saveBillingContact",
    //         data: JSON.stringify(data),
    //         dataType: 'json',
    //         timeout: ajaxTimeout,
    //         success: function (data) {
    //           if(data.success) {
    //             errorMsg.addClass('hidden');
    //             $("#saveBillingContact").prop("disabled", false);
    //             that.toggleBillingContactFormHandler();

    //             successMsg.show(function() {
    //               $(this).removeClass('hidden');
    //             });

    //             $('.billing-contact__fullname').html(firstName + ' ' + lastName);
    //             $('.billing-contact__email').html(email);
    //             $('.billing-contact__mobile').html(phone);
    //             $('.billing-contact__landline').html(landline);

    //             setTimeout(function () {
    //                 successMsg.fadeOut(fadeOutTime, function() {
    //                   $(this).addClass('hidden');
    //               });
    //             }, successTimeout);
    //           } else {
    //             $("#saveBillingContact").prop("disabled", false);
    //             errorMsg.removeClass('hidden');
    //           }
    //         },
    //         error: function (e) {
    //           $("#saveBillingContact").prop("disabled", false);
    //           errorMsg.removeClass('hidden');
    //         }
    //       });
    //     }
    //   });
    // }

  }]);

  return VenueDetails;
}();

$(function () {
  var container = '.venue-details__container';
  var venueDetails = new VenueDetails();
  if ($(container).length > 0) {
    venueDetails.init();
  }
});
