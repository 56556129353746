/*Babel ES6 transpiled!*/'use strict';

ACC.utilities = {
  /*Babel ES6 transpiled!*/clearFormFields: function clearFormFields(containerSelector) {
    var elementSelector = /*Babel ES6 transpiled!*/containerSelector + ' input, ' + containerSelector + ' textarea';
    $(elementSelector).each(function () {
      $(this).val('').change();
    });
  }
};
