/*Babel ES6 transpiled!*/"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckoutLogin = function () {
  function CheckoutLogin() {
    _classCallCheck(this, CheckoutLogin);
  }

  _createClass(CheckoutLogin, [{
    key: "bindAgeCheck",
    value: function bindAgeCheck() {
      $(document).on("click", ".js-guest-checkout", function (e) {
        e.preventDefault();
        if ($('#guestForm').valid()) {
          ACC.colorbox.open({
            href: $(this).data("link"),
            width: "709px",
            height: false,
            fixed: false,
            top: false,
            className: 'age-confirmation-container',
            onComplete: function /*Babel ES6 transpiled!*/onComplete() {
              $.colorbox.resize();

              $(document).on("click", ".js-age-cancel", function () {
                ACC.colorbox.close();
              });

              $(document).on("click", ".js-age-proceed", function () {
                $('#guestForm').submit();
              });
            },
            onError: function /*Babel ES6 transpiled!*/onError(err) {
              console.error(err);
            }
          });
        }
      });
    }
  }, {
    key: "bindLoginFormValidation",
    value: function bindLoginFormValidation() {
      $('#guestForm').validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          var $element = $(element);
          $element.closest('.form-group, .guest-checkout-checkbox').addClass('has-error');
          $element.addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          var $element = $(element);
          $element.closest('.form-group, .guest-checkout-checkbox').removeClass('has-error');
          $element.removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'help-block',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          if (element.is(":checkbox")) {
            $('.checkbox_error-message').append(error);
          } else {
            error.insertAfter(element.parent());
          }
        },
        ignore: ':hidden:not(:checkbox)',
        messages: {
          email: ACC.messageLabels.emailError,
          checkAgree: ACC.messageLabels.termsAndConditionsError
        }
      });
    }
  }]);

  return CheckoutLogin;
}();

$(function () {
  var checkout = new CheckoutLogin();
  if ($('.checkout-login').length > 0) {
    checkout.bindAgeCheck();
    checkout.bindLoginFormValidation();
  }
});
