/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckoutPayment = function () {
  function CheckoutPayment() {
    _classCallCheck(this, CheckoutPayment);
  }

  _createClass(CheckoutPayment, [{
    key: 'paymentSelectHandler',
    value: function paymentSelectHandler() {
      var radioPaymentMethod = $('.js-payment-method'),
          creditCardDetails = $('.checkout-payment__credit-card'),
          cashOnly = 'cashonly',
          CCfee = $('.js-total-credit-card, .js-credit-card-fee'),
          noCCfee = $('.js-total-no-credit-card'),
          hasSurcharge = $('.js-has-surcharge'),
          creditCardItem = $('.payment-method--creditcard .lion-selectable-item__container'),
          clsActive = 'active';

      radioPaymentMethod.on('change', function () {

        var paymentModeURL = ACC.urls.paymentSelect + $(this).val();
        var poNum = $('#poNum');
        var strPoNum = '';

        if (poNum.length) {
          strPoNum = poNum.val();
        }

        if ($(this).val() === cashOnly) {
          // Credit Card radio button is selected
          creditCardDetails.removeClass('hide');

          if (hasSurcharge.val() === 'true') {
            CCfee.removeClass('hide');
            noCCfee.addClass('hide');
          }

          if ($('.payment-method--creditcard').length != 0) {
            var url = $('.default-card').data("href");
            window.location.href = url;
          }
        } else {
          // On Account radio button is selected
          creditCardDetails.addClass('hide');
          CCfee.addClass('hide');
          noCCfee.removeClass('hide');
          creditCardItem.removeClass(clsActive);

          window.location.href = paymentModeURL + '/' + strPoNum;
        }
      });
    }
  }, {
    key: 'deliveryInstructionsHandler',
    value: function deliveryInstructionsHandler() {
      var radioDeliveryInstructions = $('.js-delivery-instructions'),
          deliveryInstructionsDiv = $('.delivery-instructions'),
          instructionsTextDiv = $('.js-authority-to-leave'),
          authorityToLeave = 'authorityToLeave',
          signedDelivery = 'signedDelivery';

      radioDeliveryInstructions.on('change', function () {
        if ($(this).val() === authorityToLeave) {
          deliveryInstructionsDiv.addClass('required');
          instructionsTextDiv.removeClass('hide');
        } else if ($(this).val() === signedDelivery) {
          deliveryInstructionsDiv.removeClass('required');
          instructionsTextDiv.addClass('hide');
        }
      });
    }
  }, {
    key: 'creditcardSelectHandler',
    value: function creditcardSelectHandler() {
      var creditCard = $('.js-btn-href'),
          ccIDField = $('#ccId'),
          poNum = $('#poNum');
      var strPoNum = '';
      creditCard.on('click', function () {

        if (poNum.length) {
          strPoNum = poNum.val();
        }

        ccIDField.val($(this).attr('data-paymentid'));

        dataHref = $(this).attr('data-href') + '/' + strPoNum;
        $(this).attr('data-href', dataHref);
      });
    }
  }, {
    key: 'displayPayment',
    value: function displayPayment() {
      var paymentRadio = $('.js-payment-method'),
          creditCardDetails = $('.checkout-payment__credit-card'),
          cashOnly = 'cashonly';
      var checkedPaymentRadio = /*Babel ES6 transpiled!*/void 0;
      if (paymentRadio.length) {
        checkedPaymentRadio = $('.js-payment-method:checked');
        if (checkedPaymentRadio.val() === cashOnly) {
          creditCardDetails.removeClass('hide');
        }
      }
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var that = this,
          form = '#placeOrderForm';

      $(form).validate({
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          $(element).closest('.form-group').addClass('has-error');
          $(element).addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          $(element).closest('.form-group').removeClass('has-error');
          $(element).removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          $(element).parents(".form-group").after(error);
        },
        rules: {
          poNum: { ponumber: true }
        }
      });
    }
  }, {
    key: 'bindLoadingOnCheckout',
    value: function bindLoadingOnCheckout() {
      var $orderForm = $('#placeOrderForm');
      var placeOrderBtn = '.btn-place-order';
      $(placeOrderBtn).click(function () {
        if (!$orderForm.valid()) return;
        if (!$(placeOrderBtn).hasClass('btn-processing')) {
          ACC.loader.showLoadingOnButton(placeOrderBtn);
          ACC.loader.showLoadingScreen();
          setTimeout(function () {
            $orderForm.submit();
          }, 500);
        }
      });
    }

    /**
     * Prevent duplicate order
     * NGP2HYBRIS-2788
     */

  }, {
    key: 'checkOrderIsSubmitted',
    value: function checkOrderIsSubmitted() {
      ACC.colorbox.open({
        html: $("#orderSubmittedModal").html(),
        width: "600px",
        height: false,
        fixed: true,
        onComplete: function /*Babel ES6 transpiled!*/onComplete() {
          $(document).on("click", ".js-order-process-ok", function () {
            ACC.colorbox.close();
          });
        },
        onClosed: function /*Babel ES6 transpiled!*/onClosed() {
          window.location.href = '/';
        }
      });
    }
  }, {
    key: 'pageLoadingScreen',
    value: function pageLoadingScreen() {
      $(document).ready(function () {
        ACC.loader.hideLoadingScreen();
      });
    }
  }]);

  return CheckoutPayment;
}();

$(function () {
  var isSSAUSite = ACC.config.isSsAuSite === 'true';
  var checkout = new CheckoutPayment();
  if ($('.checkout-payment').length > 0) {
    checkout.paymentSelectHandler(); // switch payment method radio button
    checkout.creditcardSelectHandler();
    if (isSSAUSite) {
      checkout.deliveryInstructionsHandler(); // switch delivery instructions options
    }
    checkout.displayPayment(); // display credit card section by checking radio button on page load
    checkout.validateForm();
    checkout.bindLoadingOnCheckout(); // shows a loader when complete order button is clicked
    checkout.pageLoadingScreen(); // hides loading screen after page load
    if ($('.js-order-submitted').length > 0) {
      checkout.checkOrderIsSubmitted();
    }
  }
});
