ACC.pickupinstoreaction = {

}

$(document).ready(function ()
{
	with (ACC.pickupinstoreaction)
	{

	}
});


