/*Babel ES6 transpiled!*/'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MyDetails = function () {
  function MyDetails() {
    _classCallCheck(this, MyDetails);
  }

  _createClass(MyDetails, [{
    key: 'init',
    value: function init() {
      this.validateForm();
      this.removeSpecificValidationRules();
      this.detailsContactNumber();
      this.selectCountryCode();
      this.countryCodeFormatting();
      this.detailsMobileNumber();
      this.selectMobileCountryCode();
      this.mobileCountryFormatting();
    }
  }, {
    key: 'removeSpecificValidationRules',
    value: function removeSpecificValidationRules() {
      if ($('.phone-group').length === 0) {
        $('[type="tel"]').each(function () {
          $(this).rules('remove', 'require_from_group');
        });
      }
    }
  }, {
    key: 'detailsContactNumber',
    value: function detailsContactNumber() {
      var that = this;

      if ($("#phone").val() === "") {
        $(".contact-number").removeClass("is-active");
        $(".contact-number_country-code").addClass("country-code-hidden");
      }

      $("#phoneIsoCode").focus(function () {
        $("#phoneIsoCode").addClass("contact-number_phone-focus").removeClass("country-code-hidden");
        $("#phone").addClass("contact-number_phone-focus");

        if ($("#phone").val() === "") {
          $(".contact-number").addClass("is-active");
        }
      }).blur(function () {
        that.selectCountryCode();
        $("#phoneIsoCode").removeClass("contact-number_phone-focus");
        $("#phone").removeClass("contact-number_phone-focus");

        if ($("#phone").val() === "") {
          $(".contact-number").removeClass("is-active");
          $(".contact-number_country-code").addClass("country-code-hidden");
        }
      });

      $("#phone").focus(function () {
        $("#phoneIsoCode").addClass("contact-number_phone-focus").removeClass("country-code-hidden");
        $("#phone").addClass("contact-number_phone-focus");
        $(".contact-number").addClass("is-active");
      }).blur(function () {
        $("#phoneIsoCode").removeClass("contact-number_phone-focus");
        $("#phone").removeClass("contact-number_phone-focus");

        if ($(this).val() === "") {
          $(".contact-number_country-code").addClass("country-code-hidden");
        }
      });
    }
  }, {
    key: 'detailsMobileNumber',
    value: function detailsMobileNumber() {
      var that = this;

      if ($("#mobileNumber").val() === "") {
        $(".mobile-number").removeClass("is-active");
        $(".mobile-number_country-code").addClass("mobile-code-hidden");
      }

      $("#mobileIsoCode").focus(function () {
        $("#mobileIsoCode").addClass("mobile-number_phone-focus").removeClass("mobile-country-code-hidden");
        $("#mobileNumber").addClass("mobile-number_phone-focus");

        if ($("#mobileNumber").val() === "") {
          $(".mobile-number").addClass("is-active");
        }
      }).blur(function () {
        that.selectMobileCountryCode();
        $("#mobileIsoCode").removeClass("mobile-number_phone-focus");
        $("#mobileNumber").removeClass("mobile-number_phone-focus");

        if ($("#mobileNumber").val() === "") {
          $(".mobile-number").removeClass("is-active");
          $(".mobile-number_country-code").addClass("mobile-code-hidden");
        }
      });

      $("#mobileNumber").focus(function () {
        $("#mobileIsoCode").addClass("mobile-number_phone-focus").removeClass("mobile-code-hidden");
        $("#mobileNumber").addClass("mobile-number_phone-focus");
        $(".mobile-number").addClass("is-active");
      }).blur(function () {
        $("#mobileIsoCode").removeClass("mobile-number_phone-focus");
        $("#mobileNumber").removeClass("mobile-number_phone-focus");

        if ($(this).val() === "") {
          $(".mobile-number_country-code").addClass("mobile-code-hidden");
        }
      });
    }
  }, {
    key: 'selectCountryCode',
    value: function selectCountryCode() {
      var that = this;
      $('#phoneIsoCode').change(function () {
        that.countryCodeFormatting();
      });
    }
  }, {
    key: 'countryCodeFormatting',
    value: function countryCodeFormatting() {
      var e = document.getElementById("phoneIsoCode");
      var countryCode = e.options[e.selectedIndex].text;
      if (countryCode.indexOf("-") === 4) {
        $("#phoneIsoCode").addClass("country-code-2").removeClass("country-code-1 country-code-3");
      } else if (countryCode.indexOf("-") === 3) {
        $("#phoneIsoCode").addClass("country-code-1").removeClass("country-code-2 country-code-3");
      } else {
        $("#phoneIsoCode").addClass("country-code-3").removeClass("country-code-2 country-code-1");
      }
    }
  }, {
    key: 'selectMobileCountryCode',
    value: function selectMobileCountryCode() {
      var that = this;
      $('#mobileIsoCode').change(function () {
        that.mobileCountryFormatting();
      });
    }
  }, {
    key: 'mobileCountryFormatting',
    value: function mobileCountryFormatting() {
      var e = document.getElementById("mobileIsoCode");
      var mobileCountryCode = e.options[e.selectedIndex].text;
      if (mobileCountryCode.indexOf("-") === 4) {
        $("#mobileIsoCode").addClass("mobile-country-code-2").removeClass("mobile-country-code-1 mobile-country-code-3");
      } else if (mobileCountryCode.indexOf("-") === 3) {
        $("#mobileIsoCode").addClass("mobile-country-code-1").removeClass("mobile-country-code-2 mobile-country-code-3");
      } else {
        $("#mobileIsoCode").addClass("mobile-country-code-3").removeClass("mobile-country-code-2 mobile-country-code-1");
      }
    }
  }, {
    key: 'validateForm',
    value: function validateForm() {
      var form = '.update-profile-form';
      $(form).validate({
        onfocusout: function /*Babel ES6 transpiled!*/onfocusout(element) {
          // Eager validation
          this.element(element);
        },
        rules: {
          phone: {
            phonenumber: true,
            maxlength: 10
          },
          mobileNumber: {
            phonenumber: true,
            maxlength: 10
          },
          firstName: { lionname: true },
          lastName: { lionname: true }
        },
        highlight: function /*Babel ES6 transpiled!*/highlight(element) {
          var $element = $(element);
          if ($element.hasClass('no-error-image')) $element.closest('.form-group').addClass('no-image');
          $element.closest('.form-group').addClass('has-error');
          $element.addClass('has-error').removeClass('is-valid');
        },
        unhighlight: function /*Babel ES6 transpiled!*/unhighlight(element) {
          var $element = $(element);
          if ($element.hasClass('no-error-image')) $element.closest('.form-group').removeClass('no-image');
          $element.closest('.form-group').removeClass('has-error');
          $element.removeClass('has-error').addClass('is-valid');
        },
        errorElement: 'div',
        errorClass: 'input-msg input-error',
        errorPlacement: function /*Babel ES6 transpiled!*/errorPlacement(error, element) {
          var row = $(element).parents()[2];
          var msgGroup = $(row).find('.input-msg-group');
          $(msgGroup).append(error);
        },
        messages: {
          phone: {
            phonenumber: ACC.messageLabels.phone,
            maxlength: ACC.messageLabels.contactNumberLength
          },
          mobileNumber: {
            phonenumber: ACC.messageLabels.mobileNumber,
            maxlength: ACC.messageLabels.contactNumberLength
          }
        }
      });
    }
  }]);

  return MyDetails;
}();

$(function () {
  var container = '.my-details';
  var myDetails = new MyDetails();
  if ($(container).length > 0) {
    myDetails.init();
  }
});
